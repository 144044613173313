import {Component, ViewChild, TemplateRef, OnInit} from '@angular/core';
import {startOfDay, endOfDay, subDays, isSameDay, isSameMonth, addHours} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView} from 'angular-calendar';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import {CoreService} from "../../core.service";
import {AuthService} from "../../auth.service";

registerLocaleData(localeRu);

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#38404a'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#38404a'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#38404a'
  }
};
@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.less']
})
export class ScheduleComponent implements OnInit {
  navItems = [
    {title: 'Статистика', icon: 'fa-line-chart', link: '/'},
    {title: 'Архив встреч', icon: 'fa-file-text', link: '/meetings'},
    {title: 'Календарь', icon: 'fa-file', link: '/calendar', isActive: true},
    {title: 'Ежедневный отчет', icon: 'fa-file', link: '/report'},
    // {title: 'Кейсы', icon: 'fa-briefcase', link: '/cases'},
    //{title: 'База знаний', icon: 'fa-graduation-cap', link: '/library'},
  ];
  // @ts-ignore
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = true;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: { action: string; event: CalendarEvent; };
  events: CalendarEvent[] = [];
  eventsArray = [];
  user = {};
  constructor(
      private modal: NgbModal,
      private coreService: CoreService,
      private authService: AuthService
  ) {}
  ngOnInit() {
    this.authService.user$.subscribe(user => {
      this.user = user;

      this.coreService.getEventsList(user['alias'])
        .subscribe(resp => {
          this.eventsArray = resp.map(e => {
            // @ts-ignore
            return {id: e.payload.doc['id'], ...e.payload.doc.data()} as any;
          });

          this.eventsArray.forEach(event => {
            this.events.push(
                {
                  start: this.onGetFormattedDate(event['start']),
                  title: event['title'],
                  color: colors.blue,
                  actions: this.actions,
                  draggable: true
                }
            )
          })
        })
    });
  }

  onGetFormattedDate(dateString) {
    const date = dateString.split(".");
    return new Date(date[2], (date[1] - 1), date[0], date[3], date[4]);
  }

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      a11yLabel: 'Редактировать',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      a11yLabel: 'Удалить',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.activeDayIsOpen = !((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0);
      this.viewDate = date;
    }
  }

  eventTimesChanged({ event,
                      newStart,
                      newEnd
                    }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'sm' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.yellow,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true
        }
      }
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
}
