import {Component, OnInit} from '@angular/core';
import {AdminService} from '../../admin.service';
import {User} from '../../../core/entities/user.model';
import {ActivatedRoute } from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/Rx';
import {CoreService} from '../../../core/core.service';
import {DatePipe} from "@angular/common";


@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.less']
})
export class UserDetailsComponent implements OnInit {
  activeSection: 'meetings' | 'planing';
  user: User;
  planing = [];
  reports = [];
  private destroyed$ = new Subject();
  isLoading = true;
  constructor(
      private adminService: AdminService,
      private coreService: CoreService,
      private datepipe: DatePipe,
      private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.activeSection = 'planing';
    this.adminService.getUser(this.route.snapshot.data.userData).then(user => {
      this.user = user;
      this.getPlaningList();
      this.getReportList();
    });
  }

  onChangeSection(type: 'meetings' | 'planing') {
      this.activeSection = type;
  }

  getPlaningList() {
      this.coreService.getPlaningList(this.user['alias'])
          .pipe(takeUntil(this.destroyed$))
          .subscribe(data => {
              this.planing = data.map(e => {
                  this.isLoading = false;
                  return {id: e.payload.doc['id'], ...e.payload.doc.data()};
              });
              this.planing.forEach(item => {
                  item.isEditMode = false;
              });
          });

  }
  getReportList() {
      this.coreService.getReportsList(this.user['alias'])
          .pipe(takeUntil(this.destroyed$))
          .subscribe(data => {
              this.reports = data.map(e => {
                  this.isLoading = false;
                  return {id: e.payload.doc['id'], ...e.payload.doc.data()};
              });
              this.reports.forEach(item => {
                  item.date = this.datepipe.transform(item.date.toDate(), 'dd.MM.yyyy');
                  item.isEditMode = false;
              });
          });

  }




}
