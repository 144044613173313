import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AdminService } from '../../admin.service';
import { User } from '../../../core/entities/user.model';

@Injectable()
export class UserResolver implements Resolve<{data: User}> {

    constructor(public adminService: AdminService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot): Promise<{data: User}> {

        const user = new User();

        return new Promise((resolve, reject) => {
            this.adminService.getUser(route.params['id'])
                .then(() => {
                    return resolve(route.params['id']);
                }, err => {});
        });
    }
}
