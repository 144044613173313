import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.less']
})
export class MeetingsComponent implements OnInit {
    navItems = [
        {title: 'Статистика', icon: 'fa-line-chart', link: '/dashboard'},
        {title: 'Архив встреч', icon: 'fa-file-text', link: '/meetings', isActive: true},
        {title: 'Планирование', icon: 'fa-pie-chart', link: '/planing'},
        {title: 'Ежедневный отчет', icon: 'fa-file', link: '/report'},
        // {title: 'Кейсы', icon: 'fa-briefcase', link: '/cases'},
        // {title: 'Календарь', icon: 'fa-file', link: '/calendar'},
    ];
    constructor() { }

    ngOnInit() { }

}
