import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';

@Component({
  selector: 'app-meetings-widget',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.less']
})
export class MeetingsComponent implements OnInit {
  public lineChartData: ChartDataSets[] = [
    { data: [10,20,15,40,11,22,25,1,35,75,10,4], label: 'Катерина Заруцкая' },
    { data: [25,1,35,75,10,4,10,20,15,40,11,22], label: 'Порада Александр' },
  ];
  public lineChartLabels: Label[] = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {xAxes: [{}],
      yAxes: [
        { id: 'y-axis-0',position: 'left',gridLines: { color: 'transparent' } },
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: 50,
          borderColor: '#4a8884',
          borderWidth: 1,
          label: {
            enabled: true,
            backgroundColor: '#4a8884',
            fontColor: '#fff',
            content: 'Цель'
          }
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    {
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];

  @ViewChild(BaseChartDirective, {}) chart: BaseChartDirective;
  constructor() { }

  ngOnInit() {
  }

}
