import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CoreService} from '../../core.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AuthService} from '../../auth.service';
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-manager-statistic',
  templateUrl: './manager-statistic.component.html',
  styleUrls: ['./manager-statistic.component.less']
})
export class ManagerStatisticComponent implements OnInit, OnDestroy {
  @Input() userAlias: string;
  @Input() userName: string;
  user = {};
  currentMonth = this.datepipe.transform(new Date(), 'MM');
  months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
  reports = [];
  statistic = {
    clients: 0,
    coldMeetings:  0,
    meetings:  0,
    contacts:  0,
    money:  0
  };
  private destroyed$ = new Subject();
  constructor(
      private auth: AuthService,
      private coreService: CoreService,
      private datepipe: DatePipe
  ) { }


  ngOnInit() {
      if (!this.userAlias) {
          this.auth.user$.subscribe(user => {
              this.user = user;
              this.getReportsList();
          });
      } else {
          this.getReportsList();
      }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  getReportsList() {
    this.statistic = {
      clients: 0,
      coldMeetings:  0,
      meetings:  0,
      contacts:  0,
      money:  0
    };
    this.coreService.getReportsList(this.userAlias ? this.userAlias : this.user['alias'])
        .pipe(takeUntil(this.destroyed$))
        .subscribe(data => {
          this.reports = data.map(e => {
            return {id: e.payload.doc['id'], ...e.payload.doc.data()};
          });
          this.reports.forEach(report => {
              if (this.datepipe.transform(report.date.toDate(), 'MM') === this.currentMonth) {
                  this.statistic.clients += +report.clients;
                  this.statistic.coldMeetings += +report.coldMeetings;
                  this.statistic.meetings += +report.meetings;
                  this.statistic.contacts += +report.contacts;
                  this.statistic.money += +report.money;
              }
          });
        });

  }

  onChangeMonth(month) {
      this.currentMonth = ('0' + month).slice(-2);
      this.getReportsList();
  }

}
