import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';
import { tap, map, take } from 'rxjs/operators';

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(
      private auth: AuthService,
      private router: Router
  ) {}

  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> {

    return this.auth.user$.pipe(
        take(1),
        map(user => user && user.roles.admin),
        tap(isAdmin => {
          if (!isAdmin) {
              this.router.navigate(['/login']);
              console.error('Access denied - Admins only');
          }
        })
    );

  }
}
