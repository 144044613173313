export class FirebaseUserModel {
  image: string;
  alias: string;
  email: string;
  fullName: string;
  id: string;
  phone: string;
  position: string;
  roles: any;
  shortName: string;


  constructor() {
    this.image = '';
    this.alias = '';
    this.email = '';
    this.fullName = '';
    this.id = '';
    this.phone = '';
    this.position = '';
    this.roles = [];
    this.shortName = '';
  }
}
