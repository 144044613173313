import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sender',
  templateUrl: './sender.component.html',
  styleUrls: ['./sender.component.less']
})
export class SenderComponent implements OnInit {
  activeDirectory = '/sender';

  navItems = [
      {title: 'Панель управления', icon: 'fa-gamepad', link: '/'},
      {title: 'Список сообщений', icon: 'fa-envelope-o', link: '/messages'},
      {title: 'Рассылка', icon: 'fa-comments-o', link: '/new-message'},
      {title: 'Документация', icon: 'fa-book', link: '/documentation'},
  ];

  constructor(
      private router: Router
  ) { }

  ngOnInit() {
  }

  isActiveLink(link) {
      return (link === '/' && this.router.url === this.activeDirectory) || (this.activeDirectory + link === this.router.url);
  }

}
