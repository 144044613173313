import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { AuthGuard } from './auth.guard';
import { AdminGuard } from './admin.guard';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { WebsiteAnalyzeComponent } from './tools/website-analyze/website-analyze.component';
import { WeatherComponent } from './widgets/weather/weather.component';
import { CurrencyRateComponent } from './widgets/currency-rate/currency-rate.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManagerStatisticComponent } from './widgets/manager-statistic/manager-statistic.component';
import { ScheduleComponent} from './tools/schedule/schedule.component';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter} from 'angular-calendar';
import { adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import { BsDropdownModule} from 'ngx-bootstrap';
import { LoaderComponent } from './features/loader/loader.component';
import { ContactListComponent } from './features/contact-list/contact-list.component';
import { MyFilterPipe} from './pipes/search.pipe';
import { NoResultsComponent } from './features/no-results/no-results.component';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        BsDropdownModule
    ],
    exports: [
        HeaderComponent,
        WebsiteAnalyzeComponent,
        WeatherComponent,
        CurrencyRateComponent,
        ManagerStatisticComponent,
        ScheduleComponent,
        LoaderComponent,
        MyFilterPipe,
        ContactListComponent,
        NoResultsComponent
    ],
    declarations: [
        HeaderComponent,
        WebsiteAnalyzeComponent,
        WeatherComponent,
        CurrencyRateComponent,
        ManagerStatisticComponent,
        ScheduleComponent,
        LoaderComponent,
        ContactListComponent,
        MyFilterPipe,
        NoResultsComponent
    ],
    providers: [
        AuthService,
        UserService,
        AuthGuard,
        AdminGuard,
    ],
})
export class CoreModule { }
