import {Component, OnInit} from '@angular/core';
import {SenderService} from '../sender.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-sender-dashboard',
    templateUrl: './sender-dashboard.component.html',
    styleUrls: ['./sender-dashboard.component.less']
})
export class SenderDashboardComponent implements OnInit {
    userSideError: string;
    sendMessageStatus: string;
    status = {
        qrCode: '../../../assets/img/wr.png'
    };
    messagesQueue = 0;
    loading = true;
    form: FormGroup;
    isContactsShown = false;
    sendingMessage = false;
    sended = false;

    constructor(
        private senderService: SenderService
    ) {
    }

    ngOnInit() {
        this.getStatus();
    }

    getStatus() {
        this.loading = true;
        this.senderService.getStatus()
            .subscribe(resp => {
                this.loading = false;
                this.status['accountStatus'] = this.getFormattedMessage(resp['accountStatus']);
                this.status['qrCode'] = resp['qrCode'];
                if (resp['accountStatus'] === 'authenticated') {
                    this.getQueue();
                    this.onInitForm();
                }
            }, error => {
                this.loading = false;
                this.userSideError = this.getFormattedMessage(error.error.error);
            });
    }

    private onInitForm() {
        this.form = new FormGroup({
            phoneNumber: new FormControl('', [Validators.required]),
            messageText: new FormControl('', [Validators.required])
        });
    }

    reloadQR() {
        this.loading = true;
        this.senderService.reloadQR()
            .subscribe(resp => {
                this.loading = false;
                this.status['qrCode'] = resp;
            }, error => {
                this.loading = false;
                this.userSideError = this.getFormattedMessage(error.error.error);
            });
    }

    getQueue() {
        this.loading = true;
        this.senderService.showQueue()
            .subscribe(resp => {
                this.loading = false;
                this.messagesQueue = resp['totalMessages'];
            }, error => {
                this.loading = false;
                this.userSideError = this.getFormattedMessage(error.error.error);
            });
    }

    clearQueue() {
        this.loading = true;
        this.senderService.clearQueue()
            .subscribe(() => {
                this.loading = false;
                this.messagesQueue = 0;
            }, error => {
                this.loading = false;
                this.userSideError = this.getFormattedMessage(error.error.error);
            });
    }

    reboot() {
        this.loading = true;
        this.senderService.reboot()
            .subscribe(() => {
                this.loading = false;
            }, error => {
                this.loading = false;
                this.userSideError = this.getFormattedMessage(error.error.error);
            });
    }

    logOut() {
        this.loading = true;
        this.senderService.logOut()
            .subscribe(() => {
                this.loading = false;
                this.getStatus();
            }, error => {
                this.loading = false;
                this.userSideError = this.getFormattedMessage(error.error.error);
            });
    }


    getFormattedMessage(message) {
        if (message.includes('is not paid')) {
            return 'Для дальнейшей работы необходимо пополнить аккаунт.';
        }
        if (message.includes('Please contact technical support')) {
            return 'Что - то пошло не так, нужно обратиться в службу поддержки.';
        }
        if (message.includes('got qr code')) {
            return 'Отсканируйте QR - код.';
        }
        if (message.includes('authenticated')) {
            return 'Успешно авторизован';
        }
        if (message.includes('init')) {
            return 'Аккант запускается';
        }
        if (message.includes('loading')) {
            return 'Повторите через 30 сек';
        }

        return message;
    }

    onSendMessage() {
        this.sendingMessage = true;
        const phone = +this.form.controls['phoneNumber'].value;
        const message = this.form.controls['messageText'].value;
        this.senderService.sendMessage(phone, message)
            .subscribe(resp => {
                if (resp.sent) {
                    this.form.reset();
                    this.sendMessageStatus = 'Сообщение успешно отправлено';
                    setTimeout(() => { this.sendMessageStatus = ''; }, 3000);
                } else {
                    this.sendMessageStatus = 'Не удалось отправить сообщение';
                }
                this.sendingMessage = false;
            }, () => {
                this.sendMessageStatus = 'Не удалось отправить сообщение';
                this.sendingMessage = false;
            });
    }

    onSelectContact(contact) {
        const phone = contact['phone'];
        this.form.controls['phoneNumber'].setValue(phone);
        this.isContactsShown = false;
    }

    mass() {
        const recipientList = [];
        const message = 'Вы получили это сообщение?%0AЗначит Вы его просматриваете!%0A%0AПредлагаем услугу эффективной WhatsApp-рассылки ' +
            'для Вашего бизнеса от компании WhyNet.%0A✓ рассылка-оповещение;%0A✓ рассылка-опрос;%0A✓ рассылка в виде чат-бота с логикой ' +
            'диалога;%0A%0AИнтересно? Ставьте %2b в ответ на это сообщение и мы свяжемся для обсуждения деталей!';
        if (!this.sended) {
            recipientList.forEach(item => {
                this.senderService.sendFile(item, message)
                    .subscribe(() => {
                        console.log(item, 'Message was sended');
                    }, () => {
                        console.log(item, 'Something goes wrong');
                    });
            });
        }
        this.sended = true;
    }
}
