import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';
import { User } from '../../../core/entities/user.model';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.less']
})
export class UserListComponent implements OnInit {

  constructor(
      private adminService: AdminService,
      private route: ActivatedRoute,
      private router: Router
  ) {
  }

  users: User[];
  ngOnInit() {
    this.getUsersList();
  }

  getUsersList() {
    this.adminService.getUsersList().subscribe(data => {
      this.users = data.map(e => {
        return { uid: e.payload.doc['uid'], ...e.payload.doc.data() as User};
      });
    });
  }

  openDetailsPage(id) {
    this.router.navigate([id], { relativeTo: this.route });
  }

  onAddUser() {
    /*let user: User = {
      uid: '',
      email: 'vadim.myweb@gmail.com',
      name: 'Вадим Соломаха',
      phone: '+38(093)366-97-57',
      position: 'Отдел контекстной рекламы',
      roles: {
        admin: false,
        manager: true,
        superAdmin: false
      },
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi',
      alias: 'vsolomakha',
      note: 'Заметка'
    };
    this.adminService.addUser(user)*/
  }
}
