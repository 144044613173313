import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from '../core/entities/user.model';

@Injectable()
export class DashboardService{

    constructor(private firestore: AngularFirestore) { }
}

