import {Component, Input, OnInit} from '@angular/core';
import {CoreService} from "../../core.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-website-analyze',
  templateUrl: './website-analyze.component.html',
  styleUrls: ['./website-analyze.component.less']
})
export class WebsiteAnalyzeComponent implements OnInit {
  @Input() url: string;
  activeSection = 'mobile';
  website = {
    mobile: {speed: '', speedQuality: '', screenshot: ''},
    desktop: {speed: '', speedQuality: '', screenshot: ''}
  };
  isLoading = true;
  mobileIsLoaded = false;
  desktopIsLoaded = false;

  constructor(
      private coreService: CoreService,
      private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    if (this.url ) {
      this.runPageSpeedTest('desktop');
      this.runPageSpeedTest('mobile');
    }
  }
  onChangeSection(section) {
    this.activeSection = section;
  }

  runPageSpeedTest(type){
    this.isLoading = true;
    this.coreService.getPageSpeed(this.url, type)
      .subscribe(resp => {
        console.log(resp)
        this.website[type].speed = resp.lighthouseResult.categories.performance.score * 100;
        this.website[type].screenshot = resp.lighthouseResult.audits['final-screenshot'].details.data;
        if (this.website[type].speed < 65) {
          this.website[type].speedQuality = 'плохая';
        } else if(this.website[type].speed < 85){
          this.website[type].speedQuality = 'средняя';
        } else{
          this.website[type].speedQuality = 'отличная';
        }
        type === 'mobile' ? this.mobileIsLoaded = true : this.desktopIsLoaded = true;
        this.isLoading = !(this.mobileIsLoaded && this.desktopIsLoaded);
      })
  }

  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

}
