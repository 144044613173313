import { NgModule } from '@angular/core';
import { PreloadAllModules, Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DashboardResolver } from './dashboard/dashboard.resolver';
import { AuthGuard } from './core/auth.guard';
import { AdminGuard } from './core/admin.guard';
import { AdminComponent } from './admin/admin.component';
import { UsersComponent } from './admin/users/users.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { UserListComponent } from './admin/users/user-list/user-list.component';
import {UserDetailsComponent} from './admin/users/user-details/user-details.component';
import {UserResolver} from './admin/users/user-details/user-details.resolver';
import {MeetingsComponent} from './meetings/meetings.component';
import {MeetingListComponent} from "./meetings/meeting-list/meeting-list.component";
import {MeetingDetailsComponent} from "./meetings/meeting-details/meeting-details.component";
import {MeetingResolver} from "./meetings/meeting-details/meeting-details.resolver";
import {NewMeetingComponent} from "./meetings/new-meeting/new-meeting.component";
import {LibraryComponent} from "./library/library.component";
import {DailyReportComponent} from "./daily-report/daily-report.component";
import {ScheduleComponent} from "./core/tools/schedule/schedule.component";
import {PlaningComponent} from "./planing/planing.component";
import {ChatComponent} from "./chat/chat.component";
import {SenderComponent} from "./sender/sender.component";
import {SenderDashboardComponent} from "./sender/sender-dashboard/sender-dashboard.component";
import {SenderDocumentationComponent} from "./sender/sender-documentation/sender-documentation.component";
import {SenderMessagesComponent} from "./sender/sender-messages/sender-messages.component";
import {NewMessageComponent} from "./sender/new-message/new-message.component";


export const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent,  resolve: { data: DashboardResolver}},
  { path: 'planing', component: PlaningComponent,  resolve: { data: DashboardResolver}},
  { path: 'chat', component: ChatComponent,  resolve: { data: DashboardResolver}},
  { path: 'calendar', component: ScheduleComponent,  resolve: { data: DashboardResolver}},
  { path: 'library', component: LibraryComponent,  resolve: { data: DashboardResolver}},
  { path: 'report', component: DailyReportComponent,  resolve: { data: DashboardResolver}},
  { path: 'meetings/new', component: NewMeetingComponent,  resolve: { data: DashboardResolver}},
  { path: 'meetings', component: MeetingsComponent, data: {PageName: 'Meetings'}, children: [
      { path: '', component: MeetingListComponent, data: {PageName: 'Meetings list'}},
      { path: ':id', component: MeetingDetailsComponent, data: {PageName: 'Meeting'}, resolve: {meetingData: MeetingResolver}},
  ]},
  { path: 'admin', component: AdminComponent, canActivate: [AdminGuard], children: [
      { path: '', component: AdminDashboardComponent, data: {PageName: 'Dashboard'}},
      { path: 'users', component: UsersComponent, data: {PageName: 'Users'}, children: [
          { path: '', component: UserListComponent, data: {PageName: 'Users list'}},
          { path: ':id', component: UserDetailsComponent, data: { pageName: 'User'}, resolve: {userData: UserResolver} },
      ]},
  ]},
  { path: 'sender', component: SenderComponent, canActivate: [AdminGuard], children: [
      { path: '', component: SenderDashboardComponent, data: {PageName: 'Sender Dashboard'}},
      { path: 'documentation', component: SenderDocumentationComponent, data: {PageName: 'Sender Documentation'}},
      { path: 'messages', component: SenderMessagesComponent, data: {PageName: 'Sender Messages List'}},
      { path: 'new-message', component: NewMessageComponent, data: {PageName: 'Sender New Message'}}
  ]},
  { path: 'landing', loadChildren: './landing/landing.module#LandingModule'},
  { path: '**', component: NotFoundComponent,  resolve: { data: DashboardResolver}} /*notFound должна быть тут*/
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule {}
