import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.less']
})
export class AdminDashboardComponent implements OnInit {

  users = [
    {name: 'Катерина Заруцкая', alias: 'kzaruckaya'},
    {name: 'Александр Порада', alias: 'sporada'},
    {name: 'Дмитрий Клименко', alias: 'dklimenko'}
  ];
  constructor() { }

  ngOnInit() {
  }

}
