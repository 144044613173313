import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AdminService } from './admin.service';
import { AdminComponent } from './admin.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UsersComponent } from './users/users.component';
import { UserDetailsComponent } from './users/user-details/user-details.component';
import { UserResolver } from './users/user-details/user-details.resolver';
import { CoreModule } from '../core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule} from '@angular/common/http';
import { ChartsModule} from 'ng2-charts';
import { MeetingsComponent } from './admin-dashboard/widgets/meetings/meetings.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AlertModule } from 'ngx-bootstrap/alert';
import {TooltipModule} from "ngx-bootstrap";



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgbModule.forRoot(),
    HttpClientModule,
    ChartsModule,
    NgxPaginationModule,
    AlertModule,
    TooltipModule.forRoot()
  ],
  exports: [],
  declarations: [
    AdminComponent,
    UsersComponent,
    AdminDashboardComponent,
    UserListComponent,
    UserDetailsComponent,
    MeetingsComponent,
  ],
  providers: [
      AdminService,
      UserResolver
  ]
})
export class AdminModule { }
