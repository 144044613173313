import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.less']
})
export class AdminComponent implements OnInit {

  activeDirectory = '/admin';

  navItems = [
    {title: 'Статистика', icon: 'fa-line-chart', link: '/'},
    {title: 'Сотрудники', icon: 'fa-group', link: '/users'},
  ];
  constructor(
      private router: Router
  ) { }

  ngOnInit() {}

  isActiveLink(link) {
    return (link === '/' && this.router.url === this.activeDirectory) || (this.activeDirectory + link === this.router.url);
  }
}
