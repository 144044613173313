import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../core/auth.service";
import {takeUntil} from "rxjs/operators";
import {CoreService} from "../core/core.service";
import {DatePipe} from "@angular/common";
import {Subject} from "rxjs";

@Component({
  selector: 'app-planing',
  templateUrl: './planing.component.html',
  styleUrls: ['./planing.component.less']
})
export class PlaningComponent implements OnInit, OnDestroy  {
  navItems = [
    {title: 'Статистика', icon: 'fa-line-chart', link: '/'},
    {title: 'Архив встреч', icon: 'fa-file-text', link: '/meetings'},
    {title: 'Планирование', icon: 'fa-pie-chart', link: '/planing', isActive: true},
    {title: 'Ежедневный отчет', icon: 'fa-file', link: '/report'},
    // {title: 'Календарь', icon: 'fa-file', link: '/calendar'}
  ];

  constructor(
      private coreService: CoreService,
      private auth: AuthService,
      private datepipe: DatePipe
  ) { }

  form: FormGroup;
  user = {};
  planing = [];
  isEditMode = false;
  temp = {client: '', service: '', sumCurrent: '', sumCurrent2: '', sumPlaning: '', sumPlaning2: '', payment: '', date: '', isEditMode: true};
  private destroyed$ = new Subject();
  isLoading = true;

  ngOnInit() {
    this.auth.user$.subscribe(user => {
      this.user = user;
      this.getPlaningList();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  getPlaningList() {
    this.coreService.getPlaningList(this.user['alias'])
        .pipe(takeUntil(this.destroyed$))
        .subscribe(data => {
          if(!data.length){
            this.isLoading = false;
          }
          this.planing = data.map(e => {
            this.isLoading = false;
            return {id: e.payload.doc['id'], ...e.payload.doc.data()};
          });
          this.planing.forEach(item => {
            item.isEditMode = false;
          })
        });

  }

  onAddProject() {
    this.temp.isEditMode = true;
    this.planing.unshift(this.temp);
    this.isEditMode = true;
  }


  onCancel() {
    this.isEditMode = false;
    this.planing.shift();
    this.temp = {client: '', service: '', sumCurrent: '', sumCurrent2: '', sumPlaning: '', sumPlaning2: '', payment: '', date: '', isEditMode: true};
  }

  onChangeDate(event) {
    this.temp['date'] = this.datepipe.transform(event, 'dd.MM.yyyy')
  }

  onSave(item) {
    if(!item.id) {
      item.isEditMode = false;
      this.temp['manager'] = this.user['alias'];
      this.coreService.addProject(this.temp)
    }else{
      item.isEditMode = false;
      if(this.temp.client || this.temp.service || this.temp.sumCurrent || this.temp.sumCurrent2 || this.temp.sumPlaning || this.temp.sumPlaning2 || this.temp.payment || this.temp.date){
        !this.temp.client ? this.temp.client = item.client : '';
        !this.temp.service ? this.temp.service = item.service : '';
        !this.temp.sumCurrent ? this.temp.sumCurrent = item.sumCurrent : '';
        !this.temp.sumCurrent2 ? this.temp.sumCurrent2 = item.sumCurrent2 : '';
        !this.temp.sumPlaning ? this.temp.sumPlaning = item.sumPlaning : '';
        !this.temp.sumPlaning2 ? this.temp.sumPlaning2 = item.sumPlaning2 : '';
        !this.temp.payment ? this.temp.payment = item.payment : '';
        !this.temp.date ? this.temp.date = item.date : null;
        this.coreService.editProject(item.id, this.temp);
      }
      this.temp = {client: '', service: '', sumCurrent: '', sumCurrent2: '', sumPlaning: '', sumPlaning2: '', payment: '', date: '', isEditMode: false};
    }

  }

}
