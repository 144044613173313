import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { AuthService } from '../../core/auth.service';
import {Router} from '@angular/router';
import {Meeting} from '../../core/entities/meeting.model';
import {TypeaheadMatch} from 'ngx-bootstrap';
import {DatePipe} from '@angular/common';
import {CoreService} from '../../core/core.service';
import {TelegramService} from '../../shared/services/telegram.service';
import * as firebase from 'firebase';
import {Lightbox} from 'ngx-lightbox';

@Component({
  selector: 'app-meeting',
  templateUrl: './new-meeting.component.html',
  styleUrls: ['./new-meeting.component.less']
})
export class NewMeetingComponent implements OnInit {
  @ViewChild('commentInput') commentInput: ElementRef;
  @ViewChild('commentsWrapper') commentsWrapper: ElementRef;
  navItems = [
    {title: 'Общая информация', icon: 'fa-question-circle-o', link: 'ng'},
    {title: 'Деятельность в интернете', icon: 'fa-globe', link: 'ni'},
    {title: 'Решения от WhyNet', icon: 'fa-rocket', link: 'ns'},
    {title: 'Кейсы', icon: ' fa-briefcase', link: 'cases'},
    {title: 'Итоги встречи', icon: 'fa-handshake-o', link: 'meetingResults'},
    // {title: 'Комментарии', icon: 'fa-comments', link: 'comments'},
  ];
  ng = [
    {
      title: 'Сайт компании',
      name: 'website',
      icon: 'fa-globe',
      link: 'ng-1',
      variants: [
        {title: 'https://', type: 'text', value: ''},
      ]
    },
    {
      title: 'Социальные сети',
      name: 'socials',
      icon: 'fa-instagram',
      link: 'ng-2',
      variants: [
        {title: 'Instagram', type: 'text', label: 'Instagram', value: ''},
        {title: 'Facebook', type: 'text', label: 'Facebook', value: ''},
      ]
    },
    {
      title: 'Товары или услуги?',
      name: 'type',
      icon: 'fa-gift',
      link: 'ng-3',
      variants: [
        {title: 'Товары', type: 'checkbox', isChecked: false},
        {title: 'Услуги', type: 'checkbox', isChecked: false},
      ]
    },
    {
      title: 'Компания на рынке?',
      name: 'age',
      icon: 'fa-cube',
      link: 'ng-4',
      variants: [
        {title: 'До 3 лет', type: 'checkbox', isChecked: false},
        {title: '3 - 5 лет', type: 'checkbox', isChecked: false},
        {title: '5 - 10 лет', type: 'checkbox', isChecked: false},
        {title: 'Более 10 лет', type: 'checkbox', isChecked: false},
      ]
    },
    {
      title: 'Работа на аудиторию',
      name: 'audience',
      icon: 'fa-user',
      link: 'ng-5',
      variants: [
        {title: 'b2b', type: 'checkbox', isChecked: false},
        {title: 'b2c', type: 'checkbox', isChecked: false},
        {title: 'Опт', type: 'checkbox', isChecked: false},
        {title: 'Розница', type: 'checkbox', isChecked: false}
      ]
    },
    {
      title: 'Целевая аудитория',
      name: 'geography',
      icon: 'fa-map-marker',
      link: 'ng-6',
      variants: [
        {title: 'Казахстан', type: 'checkbox', isChecked: false},
        {title: 'Украина', type: 'checkbox', isChecked: false},
        {title: 'Россия', type: 'checkbox', isChecked: false},
        {title: 'Другие', type: 'checkbox', isChecked: false},
        {title: 'Страны/города', type: 'textarea', value: ''},
      ]
    },
    {
      title: 'Источники получения клиентов',
      name: 'source',
      icon: 'fa-bullhorn',
      link: 'ng-7',
      variants: [
        {title: 'Сарафанное радио', type: 'checkbox', isChecked: false},
        {title: 'Наружная реклама', type: 'checkbox', isChecked: false},
        {title: 'ТВ, радио', type: 'checkbox', isChecked: false},
        {title: 'Торговые точки в городе', type: 'checkbox', isChecked: false},
        {title: 'Интернет', type: 'checkbox', isChecked: false},
        {title: 'Размещение на площадке SATU', type: 'checkbox', isChecked: false},
        {title: 'Размещение на площадке OLX', type: 'checkbox', isChecked: false},
        {title: 'Размещение на площадке PULSCEN', type: 'checkbox', isChecked: false},
        {title: 'Ваш вариант', type: 'textarea', value: ''},
      ]
    },
    {
      title: 'Ценовая политика компании',
      name: 'cost',
      icon: 'fa-usd',
      link: 'ng-8',
      variants: [
        {title: 'Ниже рыночных', type: 'checkbox', isChecked: false},
        {title: 'Ниже средних', type: 'checkbox', isChecked: false},
        {title: 'Средние', type: 'checkbox', isChecked: false},
        {title: 'Выше средних', type: 'checkbox', isChecked: false},
        {title: 'Такие же как у конкурентов', type: 'checkbox', isChecked: false},
        {title: 'Самые высокие на рынке', type: 'checkbox', isChecked: false},
        {title: 'Ваш вариант', type: 'textarea', value: ''},
      ]
    },
    {
      title: 'Самое приоритетное направление',
      name: 'priority',
      icon: 'fa-diamond',
      link: 'ng-9',
      variants: [
        {title: 'Все товары/услуги на сайте', type: 'checkbox', isChecked: false},
        {title: 'Ваш вариант', type: 'textarea', value: ''}
      ]
    },
    {
      title: 'Статистика',
      name: 'stat',
      icon: 'fa-line-chart',
      link: 'ng-10',
      variants: [
        {title: 'Средний чек', type: 'text', value: '', withPlaceholder: true},
        {title: 'Доход', type: 'text', value: '', withPlaceholder: true},
        {title: 'Количество новых обращений в день', type: 'text', value: '', withPlaceholder: true},
      ]
    }
  ];

  ni = [
    {
      title: 'Наличие аналитики',
      name: 'analytics',
      icon: 'fa-bar-chart',
      link: 'ni-1',
      variants: [
        {title: 'Не установленна', type: 'checkbox', isChecked: false},
        {title: 'Аналитикс', type: 'checkbox', isChecked: false},
        {title: 'Метрика', type: 'checkbox', isChecked: false},
        {title: 'Цели', type: 'checkbox', isChecked: false},
        {title: 'Коммерция', type: 'checkbox', isChecked: false},
        {title: 'Ваш вариант', type: 'textarea', value: ''}
      ]
    },
    {
      title: 'Цели компании на данном этапе',
      name: 'goals',
      icon: 'fa-crosshairs',
      link: 'ni-2',
      variants: [
        {title: 'Увеличение количества продаж', type: 'checkbox', isChecked: false},
        {title: 'Повышение узнаваемости (лояльности)', type: 'checkbox', isChecked: false},
        {title: 'Продвижение бренда', type: 'checkbox', isChecked: false},
        {title: 'Формирование положительного имиджа', type: 'checkbox', isChecked: false},
        {title: 'Увеличение среднего чека', type: 'checkbox', isChecked: false},
        {title: 'Рост колличества повторных продаж', type: 'checkbox', isChecked: false},
        {title: 'Открытие новых рынков сбыта', type: 'checkbox', isChecked: false},
        {title: 'Снижение стоимости 1 клиента', type: 'checkbox', isChecked: false}
      ]
    },
    {
      title: 'Контекст',
      name: 'context',
      icon: 'fa-google',
      link: 'ni-3',
      variants: [
        {title: 'Опыта нет', type: 'checkbox', isChecked: false},
        {title: 'Работает с компанией', type: 'checkbox', isChecked: false},
        {title: 'Ведет самостоятельно', type: 'checkbox', isChecked: false},
        {title: 'Не отработал', type: 'checkbox', isChecked: false},
      ]
    },
    {
      title: 'SEO',
      name: 'seo',
      icon: 'fa-search',
      link: 'ni-4',
      variants: [
        {title: 'Не продвигают', type: 'checkbox', isChecked: false},
        {title: '1 - 6 месяцев (есть вывод)', type: 'checkbox', isChecked: false},
        {title: '1 - 6 месяцев (нет вывода)', type: 'checkbox', isChecked: false},
        {title: 'от 6 месяцев (есть вывод)', type: 'checkbox', isChecked: false},
        {title: 'от 6 месяцев (нет вывода)', type: 'checkbox', isChecked: false},
      ]
    },
    {
      title: 'SMM',
      name: 'smm',
      icon: 'fa-facebook-square',
      link: 'ni-5',
      variants: [
        {title: 'Нет опыта', type: 'checkbox', isChecked: false},
        {title: 'Есть страница (самостоятельно)', type: 'checkbox', isChecked: false},
        {title: 'Есть страница (агентство)', type: 'checkbox', isChecked: false},
        {title: 'Работает таргетированная реклама', type: 'checkbox', isChecked: false},
        {title: 'Ведется страница (публикации, сториз)', type: 'checkbox', isChecked: false},
      ]
    },
    {
      title: 'YouTube',
      name: 'youTube',
      icon: 'fa-youtube-square',
      link: 'ni-6',
      variants: [
        {title: 'Есть канал', type: 'checkbox', isChecked: false},
        {title: 'Нет канала', type: 'checkbox', isChecked: false},
      ]
    },
    {
      title: 'Отслеживание телефонных звонков',
      name: 'callTracking',
      icon: 'fa-volume-control-phone',
      link: 'ni-7',
      variants: [
        {title: 'Есть учет и прослушивание', type: 'checkbox', isChecked: false},
        {title: 'Нет опыта', type: 'checkbox', isChecked: false},
      ]
    },
  ];

  ns = [
    {
      title: 'Принцип работы',
      icon: 'fa-bell-o',
      link: 'ns-1',
      type: 'presentation',
      slides: [
        { src: '../../assets/img/presentation/principles/2.png', type: 'img' },
        { src: '../../assets/img/presentation/principles/1.png', type: 'img' }
      ]
    },
    {
      title: 'Аналитика',
      icon: 'fa-bar-chart',
      link: 'ns-2',
      type: 'presentation',
      slides: [
        { src: '../../assets/img/presentation/analytics/1.png', type: 'img' }
      ]
    },
    {
      title: 'Анализ сайта',
      icon: 'fa-television',
      link: 'ns-3',
      type: 'websiteAnalyze'

    },
    {
      title: 'Контекст',
      icon: 'fa-google',
      link: 'ns-4',
      type: 'presentation',
      slides: [
        { src: '../../assets/img/presentation/context/6.png', type: 'img' },
        { src: '../../assets/img/presentation/context/01.png', type: 'img' },
        { src: '../../assets/img/presentation/context/02.png', type: 'img' },
        { src: '../../assets/img/presentation/context/03.png', type: 'img' },
        { src: '../../assets/img/presentation/context/04.png', type: 'img' },
        { src: '../../assets/img/presentation/context/1.png', type: 'img' },
        { src: '../../assets/img/presentation/context/2.png', type: 'img' },
        { src: '../../assets/img/presentation/context/3.png', type: 'img' },
        { src: '../../assets/img/presentation/context/4.png', type: 'img' },
        { src: '../../assets/img/presentation/context/9.png', type: 'img' },
        { src: '../../assets/img/presentation/context/7.png', type: 'img' },
        { src: '../../assets/img/presentation/context/8.png', type: 'img' }
      ]
    },
    {
      title: 'Таргетированная реклама',
      icon: 'fa-line-chart',
      link: 'ns-5',
      type: 'presentation',
      slides: [
        { src: '../../assets/img/presentation/target/p/1.png', type: 'img' },
        { src: '../../assets/img/presentation/target/p/2.png', type: 'img' },
        { src: '../../assets/img/presentation/target/p/3.png', type: 'img' },
        { src: '../../assets/img/presentation/target/p/4.png', type: 'img' },

      ]
    },
    {
      title: 'SMM',
      icon: 'fa-facebook-square',
      link: 'ns-6',
      type: 'presentation',
      slides: [
        { src: '../../assets/img/presentation/smm/1-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/2-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/3-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/4-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/5-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/6-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/7-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/8-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/9-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/10-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/11-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/12-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/13-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/14-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/15-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/16-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/17-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/18-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/19-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/20-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/21-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/22-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/23-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/24-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/25-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/26-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/27-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/28-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/29-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/30-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/31-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/32-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/33-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/34-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/35-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/36-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/37-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/38-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/39-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/40-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/41-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/42-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/43-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/44-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/45-min.jpg', type: 'img' },
        { src: '../../assets/img/presentation/smm/46-min.jpg', type: 'img' }
      ]
    },
    {
      title: 'YouTube',
      icon: 'fa-youtube-square',
      link: 'ns-7',
      type: 'presentation',
      slides: [
        { src: '../../assets/img/presentation/youtube/1.png', type: 'img' },
        { src: '../../assets/img/presentation/youtube/2.png', type: 'img' },
        { src: '../../assets/img/presentation/youtube/3.png', type: 'img' },
        { src: '../../assets/img/presentation/youtube/4.png', type: 'img' },
        { src: '../../assets/img/presentation/youtube/5.png', type: 'img' },
        { src: '../../assets/img/presentation/youtube/6.png', type: 'img' },
        { src: '../../assets/img/presentation/youtube/7.png', type: 'img' },
        { src: '../../assets/img/presentation/youtube/8.png', type: 'img' },
        { type: 'title', text: 'Примеры видео:' },
        { url: 'https://www.youtube.com/embed/BLWoX-RGGPQ', type: 'youtube' },
        { url: 'https://www.youtube.com/embed/mj-uo9zotK0', type: 'youtube' },
      ]
    },
    /*{
      title: 'Call Tracking',
      icon: 'fa-volume-control-phone',
      link: 'ns-8',
      type: 'presentation',
      slides: [
        { src: '../../assets/img/presentation/calltracking/1.jpg', type: 'img' },
        { src: '../../assets/img/presentation/calltracking/2.jpg', type: 'img' },
        { src: '../../assets/img/presentation/calltracking/3.jpg', type: 'img' },
        { src: '../../assets/img/presentation/calltracking/4.jpg', type: 'img' },
        { src: '../../assets/img/presentation/calltracking/5.jpg', type: 'img' },
        { src: '../../assets/img/presentation/calltracking/6.jpg', type: 'img' },
        { src: '../../assets/img/presentation/calltracking/7.jpg', type: 'img' },
        { src: '../../assets/img/presentation/calltracking/8.jpg', type: 'img' }
      ]
    },
    {
      title: 'Email',
      icon: 'fa-at',
      link: 'ns-9',
      type: 'presentation',
      slides: [
        { src: '../../assets/img/presentation/email/1.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/2.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/3.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/4.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/5.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/6.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/7.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/8.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/9.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/10.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/11.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/12.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/13.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/14.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/15.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/16.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/17.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/18.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/19.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/20.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/21.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/22.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/23.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/24.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/25.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/26.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/27.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/28.jpg', type: 'img' },
        { src: '../../assets/img/presentation/email/29.jpg', type: 'img' }
      ]
    },*/
    {
      title: 'MyTarget',
      icon: 'fa-map-pin',
      link: 'ns-8',
      type: 'presentation',
      slides: [
        { src: '../../assets/img/presentation/mytarget/1.jpg', type: 'img' },
        { src: '../../assets/img/presentation/mytarget/2.jpg', type: 'img' },
        { src: '../../assets/img/presentation/mytarget/3.jpg', type: 'img' },
        { src: '../../assets/img/presentation/mytarget/4.jpg', type: 'img' },
        { src: '../../assets/img/presentation/mytarget/5.jpg', type: 'img' },
        { src: '../../assets/img/presentation/mytarget/6.jpg', type: 'img' },
        { src: '../../assets/img/presentation/mytarget/7.jpg', type: 'img' },
        { src: '../../assets/img/presentation/mytarget/8.jpg', type: 'img' },
        { src: '../../assets/img/presentation/mytarget/9.jpg', type: 'img' },
        { src: '../../assets/img/presentation/mytarget/10.jpg', type: 'img' },
        { src: '../../assets/img/presentation/mytarget/11.jpg', type: 'img' }
      ]
    },
    {
      title: 'Рекомендации..',
      name: '',
      icon: 'fa-rocket',
      link: 'ns-9',
      type: 'recommendations',
      recommendations: [
        {
          title: 'Аудит:',
          name: 'audit',
          variants: [
            {title: 'Анализ GoogleAdwords', type: 'checkbox', isChecked: false},
            {title: 'Анализ GoogleAnalytics', type: 'checkbox', isChecked: false},
            {title: 'Анализ ЯндексДирект', type: 'checkbox', isChecked: false},
            {title: 'Анализ ЯндексМетрики', type: 'checkbox', isChecked: false},
            {title: 'Анализ страницы Instagram', type: 'checkbox', isChecked: false},
            {title: 'Анализ рекламы Facebook/Instagram', type: 'checkbox', isChecked: false},
          ]
        },
        {
          title: 'Аналитика:',
          name: 'analytics',
          variants: [
            {title: 'Установка GoogleAnalytics', type: 'checkbox', isChecked: false},
            {title: 'Настройка целей в GoogleAnalytics', type: 'checkbox', isChecked: false},
            {title: 'Установка ЯндексМетрики', type: 'checkbox', isChecked: false},
            {title: 'Детализированный отчет', type: 'checkbox', isChecked: false},
          ]
        },
        {
          title: 'Доработка сайта:',
          name: 'website',
          variants: [
            {title: 'Адаптивность', type: 'checkbox', isChecked: false},
            {title: 'Скорость загрузки', type: 'checkbox', isChecked: false},
            {title: 'Доработки для запуска рекламы', type: 'checkbox', isChecked: false}
          ]
        },
        {
          title: 'Контекст:',
          name: 'context',
          variants: [
            {title: 'Контекстная реклама Google', type: 'checkbox', isChecked: false},
            {title: 'Контекстная реклама Яндекс', type: 'checkbox', isChecked: false},
            {title: 'КМС/РСЯ', type: 'checkbox', isChecked: false},
            {title: 'Ремаркетинг/Ретаргетинг', type: 'checkbox', isChecked: false},
            {title: 'Умный ремаркетинг', type: 'checkbox', isChecked: false},
            {title: 'РК "Gmail"', type: 'checkbox', isChecked: false},
            {title: 'Объявления с номером телефона', type: 'checkbox', isChecked: false}
          ]
        },
        {
          title: 'SMM:',
          name: 'smm',
          variants: [
            {title: 'Ведение Instagram', type: 'checkbox', isChecked: false},
            {title: 'Ведение Facebook', type: 'checkbox', isChecked: false},
            {title: 'РК "Трафик"', type: 'checkbox', isChecked: false},
            {title: 'РК "Лидогенерация"', type: 'checkbox', isChecked: false},
            {title: 'Рекламные посты', type: 'checkbox', isChecked: false},
            {title: 'InstaLanding', type: 'checkbox', isChecked: false},
            {title: 'РК на другие цели', type: 'checkbox', isChecked: false}
          ]
        },
        {
          title: 'SEO:',
          name: 'seo',
          variants: [
            {title: 'Анализ текущей ситуации', type: 'checkbox', isChecked: false},
            {title: 'Просчет стоимости и сроков', type: 'checkbox', isChecked: false}
          ]
        },
        {
          title: 'YouTube:',
          name: 'youtube',
          variants: [
            {title: 'Ведение канала', type: 'checkbox', isChecked: false},
            {title: 'Продвижение видеороликов', type: 'checkbox', isChecked: false},
            {title: 'Разработка DoodleVideo', type: 'checkbox', isChecked: false}
          ]
        },
        {
          title: 'Рассылка:',
          name: 'mailing',
          variants: [
            {title: 'What`sApp', type: 'checkbox', isChecked: false},
            {title: 'E-mail', type: 'checkbox', isChecked: false}
          ]
        },
        {
          title: 'WEB:',
          name: 'web',
          variants: [
            {title: 'Landing-page', type: 'checkbox', isChecked: false},
            {title: 'Корпоративный', type: 'checkbox', isChecked: false},
            {title: 'Мультилендинг', type: 'checkbox', isChecked: false},
            {title: 'Интернет-каталог', type: 'checkbox', isChecked: false},
            {title: 'Интернет-магазин', type: 'checkbox', isChecked: false}
          ]
        },
        {
          title: 'Другое:',
          name: 'other',
          variants: [
            {title: 'Перекрестная реклама', type: 'checkbox', isChecked: false},
            {title: 'MyTarget', type: 'checkbox', isChecked: false},
            {title: 'Товарные объявления', type: 'checkbox', isChecked: false},
            {title: 'Настройка GoogleМойБизнес', type: 'checkbox', isChecked: false},
            {title: 'Чат-бот', type: 'checkbox', isChecked: false},
            {title: 'Разработка логотипа', type: 'checkbox', isChecked: false},
            {title: 'Настройка протокола https', type: 'checkbox', isChecked: false},
            {title: 'Хостинг', type: 'checkbox', isChecked: false},
            {title: '2GIS', type: 'checkbox', isChecked: false}
          ]
        }

        /*{
          title: 'Аналитика:',
          name: 'analytics',
          variants: [
            {title: 'Установка', type: 'checkbox', isChecked: false},
            {title: 'Настройка', type: 'checkbox', isChecked: false},
            {title: 'Аудит', type: 'checkbox', isChecked: false}
          ]
        },
        {
          title: 'Анализ сайта:',
          name: 'websiteAnalyze',
          variants: [
            {title: 'Адаптивность', type: 'checkbox', isChecked: false},
            {title: 'Скорость загрузки', type: 'checkbox', isChecked: false}
          ]
        },
        {
          title: 'Маркет.анализ',
          name: 'marketAnalyze',
          variants: [
            {title: 'Проведение анализа', type: 'checkbox', isChecked: false}
          ]
        },
        {
          title: 'YouTube',
          name: 'youTube',
          variants: [
            {title: 'Анализ канала', type: 'checkbox', isChecked: false},
            {title: 'Ведение канала', type: 'checkbox', isChecked: false}
          ]
        },
        {
          title: 'Call Tracking',
          name: 'callTracking',
          variants: [
            {title: 'Установка', type: 'checkbox', isChecked: false},
            {title: 'Настройка', type: 'checkbox', isChecked: false},
            {title: 'Ведение аналитики', type: 'checkbox', isChecked: false}
          ]
        },
        {
          title: 'Контекст',
          name: 'context',
          variants: [
            {title: 'Поиск', type: 'checkbox', isChecked: false},
            {title: 'КМС', type: 'checkbox', isChecked: false},
            {title: 'Ремаркетинг', type: 'checkbox', isChecked: false},
            {title: 'Анализ аккаунта', type: 'checkbox', isChecked: false}
          ]
        },
        {
          title: 'SEO',
          name: 'seo',
          variants: [
            {title: 'Аудит сайта', type: 'checkbox', isChecked: false},
            {title: 'Проверка показателей', type: 'checkbox', isChecked: false}
          ]
        },
        {
          title: 'SMM',
          name: 'smm',
          variants: [
            {title: 'Анализ сообщества', type: 'checkbox', isChecked: false},
            {title: 'Бизнес-страница', type: 'checkbox', isChecked: false},
            {title: 'Ведение сообщества', type: 'checkbox', isChecked: false},
            {title: 'Реклама(без ведения)', type: 'checkbox', isChecked: false}
          ]
        },
        {
          title: 'E-mail',
          name: 'email',
          variants: [
            {title: 'Сбор базы', type: 'checkbox', isChecked: false},
            {title: 'Построение стратегии и реализация', type: 'checkbox', isChecked: false}
          ]
        },
        {
          title: 'WEB',
          name: 'web',
          variants: [
            {title: 'Создание сайта', type: 'checkbox', isChecked: false},
            {title: 'Адаптация сайта', type: 'checkbox', isChecked: false},
            {title: 'Оптимизация сайта', type: 'checkbox', isChecked: false},
          ]
        }*/
      ]
    }
  ];

  cases = [
    {
      title: 'WEB',
      icon: 'fa-television',
      link: 'cases-1',
      type: 'case',
      items: [
        {title: 'GastronomyLab', src: '../../assets/img/cases/web/1.jpg', link: 'https://gastronomylab.kz/'},
        {title: 'Men\'s Box', src: '../../assets/img/cases/web/2.jpg', link: 'http://mensbox.store/'},
        {title: 'Abaila', src: '../../assets/img/cases/web/3.jpg', link: 'http://abaila.kz/home'},
        {title: 'Maryanni & Partners', src: '../../assets/img/cases/web/4.jpg', link: 'https://maryanni.com/'},
        {title: '«ТАУ»', src: '../../assets/img/cases/web/5.jpg', link: 'http://omztau.kz/'},
        {title: 'MyLife - MyTRip', src: '../../assets/img/cases/web/6.jpg', link: 'http://mylife-mytrip.com/'},
        {title: 'Charity Music Run', src: '../../assets/img/cases/web/7.jpg', link: 'https://nurbankmusicrun.kz/'},
        {title: 'Mandala Academy', src: '../../assets/img/cases/web/8.jpg', link: 'http://www.mandala.academy/'},
        {title: 'Hyundai', src: '../../assets/img/cases/web/9.jpg', link: 'http://hyundaitrucks.kz/'},
        {title: 'Zolotynka', src: '../../assets/img/cases/web/10.jpg', link: 'http://zolotynka.com.ua/'},
        {title: 'Нейроэксперт', src: '../../assets/img/cases/web/11.jpg', link: 'http://neuroexpert.com.ua/'},
        {title: 'SmartAdventures', src: '../../assets/img/cases/web/12.jpg', link: 'http://s-a.com.ua/'},
        {title: 'Рафт сервис', src: '../../assets/img/cases/web/13.jpg', link: 'http://raft-service.kz/'},
        {title: 'Accounting & Consulting Company', src: '../../assets/img/cases/web/14.jpg', link: 'http://acc-kz.com/'},
        /*{title: 'Oris', src: '../../assets/img/cases/web/15.jpg', link: 'http://orisspace.io/'},*/
        {title: 'Midea', src: '../../assets/img/cases/web/16.jpg', link: 'http://midea.com.kz/'},
      ]
    },
    {
      title: 'Контекст',
      icon: 'fa-google',
      link: 'cases-2',
      type: 'case-img',
      items: [
        { src: '../../assets/img/cases/context/1.png', type: 'img' },
        { src: '../../assets/img/cases/context/2.png', type: 'img' },
        { src: '../../assets/img/cases/context/3.png', type: 'img' },
        { src: '../../assets/img/cases/context/4.png', type: 'img' },
        { src: '../../assets/img/cases/context/5.png', type: 'img' },
        { src: '../../assets/img/cases/context/6.png', type: 'img' },
        { src: '../../assets/img/cases/context/7.png', type: 'img' },
        { src: '../../assets/img/cases/context/8.png', type: 'img' },
      ]
    },
    {
      title: 'Таргетированная реклама',
      icon: 'fa-google',
      link: 'cases-3',
      type: 'case-img',
      items: [
        { src: '../../assets/img/presentation/target/1.png', type: 'img' },
        { src: '../../assets/img/presentation/target/2.png', type: 'img' },
        { src: '../../assets/img/presentation/target/3.png', type: 'img' },
        { src: '../../assets/img/presentation/target/4.png', type: 'img' },
        { src: '../../assets/img/presentation/target/5.png', type: 'img' },
        { src: '../../assets/img/presentation/target/6.png', type: 'img' },
        { src: '../../assets/img/presentation/target/7.png', type: 'img' },
        { src: '../../assets/img/presentation/target/8.png', type: 'img' },
        { src: '../../assets/img/presentation/target/9.png', type: 'img' },
        { src: '../../assets/img/presentation/target/10.png', type: 'img' },
        { src: '../../assets/img/presentation/target/11.png', type: 'img' },
        { src: '../../assets/img/presentation/target/12.png', type: 'img' },
      ]
    },
    {
      title: 'Детализированный отчет',
      icon: 'fa-file-text',
      link: 'cases-4',
      type: 'case-img',
      items: [
        { src: '../../assets/img/presentation/report/1.png', type: 'img' },
        { src: '../../assets/img/presentation/report/2.png', type: 'img' },
        { src: '../../assets/img/presentation/report/3.png', type: 'img' },
        { src: '../../assets/img/presentation/report/4.png', type: 'img' },
        { src: '../../assets/img/presentation/report/5.png', type: 'img' },
        { src: '../../assets/img/presentation/report/6.png', type: 'img' },
        { src: '../../assets/img/presentation/report/7.png', type: 'img' },
        { src: '../../assets/img/presentation/report/8.png', type: 'img' },
        { src: '../../assets/img/presentation/report/9.png', type: 'img' },
        { src: '../../assets/img/presentation/report/10.png', type: 'img' },
        { src: '../../assets/img/presentation/report/11.png', type: 'img' },
        { src: '../../assets/img/presentation/report/12.png', type: 'img' },
        { src: '../../assets/img/presentation/report/13.png', type: 'img' },
        { src: '../../assets/img/presentation/report/14.png', type: 'img' },
        { src: '../../assets/img/presentation/report/15.png', type: 'img' },
        { src: '../../assets/img/presentation/report/16.png', type: 'img' },
        { src: '../../assets/img/presentation/report/17.png', type: 'img' },
        { src: '../../assets/img/presentation/report/18.png', type: 'img' },
        { src: '../../assets/img/presentation/report/19.png', type: 'img' },
        { src: '../../assets/img/presentation/report/20.png', type: 'img' },
        { src: '../../assets/img/presentation/report/21.png', type: 'img' },
        { src: '../../assets/img/presentation/report/22.png', type: 'img' },
        { src: '../../assets/img/presentation/report/23.png', type: 'img' },
        { src: '../../assets/img/presentation/report/24.png', type: 'img' },
        { src: '../../assets/img/presentation/report/25.png', type: 'img' },
        { src: '../../assets/img/presentation/report/26.png', type: 'img' },
      ]
    },
    {
      title: 'SMM',
      icon: 'fa-facebook-square',
      link: 'cases-5',
      type: 'case',
      items: []
    },
  ];
  user = {};
  form: FormGroup;
  meeting = {
    comments: [],
    meetings: []
  };
  meetingType = 'first';
  isMeetingTypeSelected = false;
  meetingStarted = false;
  showResults = false;
  activeSection;
  activeSectionName = '';
  activeTopic = '';

  nameSelected: string;
  companySelected: string;
  websiteSelected: string;
  typeaheadLoading: boolean;
  meetings = [];
  selectedMeeting = {};

  isCommentsCollapsed = true;

  constructor(
      private router: Router,
      private auth: AuthService,
      private coreService: CoreService,
      private datepipe: DatePipe,
      private telegramService: TelegramService,
      private _lightbox: Lightbox
  ) { }

  ngOnInit() {
    this.auth.user$.subscribe(user => {
      this.user = user;
      if (sessionStorage.getItem('meetingId')) {
        this.onMeetingTypeSelect('second');
      }
    });
    this.initForm();

    if (sessionStorage.getItem('meeting')) {
      this.getFromSessionStorage();
      this.meetingStarted = true;
      this.activeSectionName = 'ng';
      this.onShowSection(this.activeSectionName);
    }
  }

  initForm() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', []),
      phone: new FormControl('', [Validators.required]),
      company: new FormControl('', []),
      time: new FormControl('15 - 30 минут', []),
    });
  }

  prepareForSave(list) {
    list.forEach(question => {
      const variants = [];
      if ( question.variants) {
        question.variants.forEach(variant => {
          if (variant.isChecked) { variants.push(variant.title); }
          if (variant.value && variant.value.length > 0) { variants.push(variant.value); }
        });
      }

      if (question.type === 'recommendations') {
        this.meeting['recommendations'] = {};
        question.recommendations.forEach(recommendation => {
          const rec = [];
          recommendation.variants.forEach(variant => {
            if (variant.isChecked) { rec.push(variant.title); }
          });

          if (rec.length > 0) {
            this.meeting['recommendations'][recommendation.name] = rec;
          }
        });
      }

      if (variants.length > 0) {
        this.meeting[question.name] = variants;
      }
    });
  }

  onChangeMeetingType() {
    this.isMeetingTypeSelected = false;
    sessionStorage.clear();
  }

  onMeetingTypeSelect(type: string) {
    sessionStorage.setItem('meetingType', JSON.stringify(type));
    this.meetingType = type;
    this.isMeetingTypeSelected = true;
    if (type === 'second') {
      this.getList();
    }
  }

  getList() {
    this.coreService.getMeetingsList().subscribe(data => {
      this.meetings = data.reverse().map(e => {
        return { id: e.payload.doc['id'], ...e.payload.doc.data() } as Meeting;
      });

      if (sessionStorage.getItem('meetingId')) {
        this.meetings.forEach(meeting => {
          if (meeting['id'] === sessionStorage.getItem('meetingId')) {
            this.selectedMeeting =  meeting;
          }
        });
      }

    });
  }

  onStart() {
    if (this.meetingType === 'first') {
      this.meeting['name'] = this.form.controls['name'].value;
      this.meeting['email'] = this.form.controls['email'].value;
      this.meeting['phone'] = this.form.controls['phone'].value;
      this.meeting['time'] = this.form.controls['time'].value;
      this.meeting['company'] = this.form.controls['company'].value;
      this.meeting['date'] = new Date();
    } else if (this.meetingType === 'second') {

      this.meeting['name'] = this.selectedMeeting['name'];
      this.meeting['email'] = this.selectedMeeting['email'];
      this.meeting['phone'] = this.selectedMeeting['phone'];
      this.meeting['company'] = this.selectedMeeting['company'];
      this.meeting['date'] = new Date();
      this.meeting['time'] = '15 - 30 минут';
      this.meeting['comments'] = this.selectedMeeting['comments'];
      this.meeting['meetings'] = this.selectedMeeting['meetings'];


      this.ng = JSON.parse(this.selectedMeeting['meetings'][this.selectedMeeting['meetings'].length - 1]['ng']);
      this.ni = JSON.parse(this.selectedMeeting['meetings'][this.selectedMeeting['meetings'].length - 1]['ni']);
      this.ns = JSON.parse(this.selectedMeeting['meetings'][this.selectedMeeting['meetings'].length - 1]['ns']);
    }

    this.meetingStarted = true;
    this.activeSectionName = 'ng';
    this.onShowSection(this.activeSectionName);
    this.setToSessionStorage();
  }

  onNextMeetingDateSelect(event) {
    this.meeting['nextDate'] = this.datepipe.transform(event, 'dd.MM.yyyy');
    sessionStorage.setItem('meeting', JSON.stringify(this.meeting));
  }

  onChangeTime(value) {
    this.meeting['time'] = value;
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      const comment = this.commentInput.nativeElement.value;
      if (comment.length > 5) {
        this.meeting['comments'].push({text: this.commentInput.nativeElement.value, author: this.user['name']});
        this.commentInput.nativeElement.value = '';
        this.setToSessionStorage();
      }
      this.scrollToBottom();
    }
  }

  scrollToBottom(): void {
    try {
      this.commentsWrapper.nativeElement.scrollTop = this.commentsWrapper.nativeElement.scrollHeight;
    } catch (err) { }
  }

  onFinish() {
    // tslint:disable-next-line:max-line-length
    const dateForFirebase = firebase.firestore.Timestamp.fromDate(new Date(this.datepipe.transform(this.meeting['date'], 'MM.dd.yyyy'))).toDate();
    const stringDate = this.datepipe.transform(this.meeting['date'], 'dd.MM.yyyy');

    this.prepareForSave(this.ng);
    this.prepareForSave(this.ni);
    this.prepareForSave(this.ns);
    this.meeting['manager'] = this.user['name'];
    this.meeting['managerAlias'] = this.user['alias'];
    // @ts-ignore
    this.meeting['duration'] = Math.floor(((new Date() - this.meeting['date']) / 60000));
    this.meeting['date'] = dateForFirebase;
    this.meeting['meetings'].push(
        {
          date: stringDate,
          time: this.meeting['time'] ? this.meeting['time'] : '',
          duration: this.meeting['duration'],
          ng: JSON.stringify(this.ng),
          ni: JSON.stringify(this.ni),
          ns: JSON.stringify(this.ns)
        }
    );

    if (this.meetingType === 'first') {
      this.coreService.addMeeting(this.meeting)
          .then(() => {
            sessionStorage.clear();
            this.router.navigate(['/meetings']);
          }, err => { console.log(err); });
    } else {
      this.coreService.updateMeeting(this.selectedMeeting['id'], this.meeting)
          .then(() => {
            sessionStorage.clear();
            this.router.navigate(['/meetings']);
          }, err => { console.log(err); });
    }

    const message = 'Компания: <b>' + this.meeting['company'] + '</b>\n' +
        'Контактное лицо: <b>' + this.meeting['name'] + '</b>\n' +
        'Номер: <b>' + this.meeting['phone'] + '</b>\n' +
        'Менеджер: <b>' + this.meeting['manager'] + '</b>\n' +
        'Дата: <b>' + stringDate + '</b>\n' +
        'Длительность: <b>' + this.meeting['duration'] + ' мин.</b>\n' +
        'Тип встречи: <b>' + ((this.meetingType === 'first') ? 'Холодная' : 'Повторная') + '</b>';

    this.sendToTelegram(encodeURIComponent(message));
  }

  sendToTelegram(message) {
    this.telegramService.sendMessage(message)
      .subscribe(
        resp => {console.log(resp); },
        err => {console.log(err); }
      );
  }

  onCancel() {
    sessionStorage.clear();
    this.router.navigate(['/meetings']);
  }

  generateResume() {
      this.prepareForSave(this.ng);
      this.prepareForSave(this.ni);
      this.prepareForSave(this.ns);
      this.showResults = true;
  }

  onShowSection(section) {
    this.activeSectionName = section;
    if (section === 'ng') {this.activeSection = this.ng; }
    if (section === 'ns') {this.activeSection = this.ns; }
    if (section === 'ni') {this.activeSection = this.ni; }
    if (section === 'cases') { this.activeSection = this.cases; }
    if (section === 'meetingResults') { this.activeSection = 'meetingResults'; }
    this.onShowTopic(section + '-1');
  }

  onShowTopic(topic) {
    this.activeTopic = topic;
  }

  onGetDate(timestamp) {
    return new Date(timestamp.seconds * 1000).toLocaleDateString();
  }

  setToSessionStorage() {
    sessionStorage.setItem('ng', JSON.stringify(this.ng));
    sessionStorage.setItem('ni', JSON.stringify(this.ni));
    sessionStorage.setItem('ns', JSON.stringify(this.ns));
    sessionStorage.setItem('meeting', JSON.stringify(this.meeting));
  }
  getFromSessionStorage() {
    this.ng = JSON.parse(sessionStorage.getItem('ng'));
    this.ni = JSON.parse(sessionStorage.getItem('ni'));
    this.ns = JSON.parse(sessionStorage.getItem('ns'));
    this.meeting = JSON.parse(sessionStorage.getItem('meeting'));
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch, type: string): void {
    this.meetings.forEach(meeting => {
      if (meeting[type] === e.value || meeting[type][0] === e.value) {
        this.selectedMeeting =  meeting;
      }
    });
  }

  onRemoveSelected() {
    this.selectedMeeting =  {};
    this.nameSelected = '';
    this.companySelected = '';
    this.websiteSelected = '';
  }

  stringToLowerCase(str) {
    return str.toLowerCase();
  }

  onGetObjectKeys(obj) {
    return Object.keys(obj);
  }

  onGetRecommendationName(str) {
    switch (this.stringToLowerCase(str)) {
      case 'audit' : return 'Аудит';
      case 'analytics' : return 'Аналитика';
      case 'website' : return 'Доработка сайта';
      case 'context' : return 'Контекст';
      case 'smm' : return 'SMM';
      case 'seo' : return 'SEO';
      case 'youtube' : return 'YouTube';
      case 'mailing' : return 'Рассылка';
      case 'web' : return 'WEB';
      case 'other' : return 'Другое';
      case 'websiteanalyze' : return 'Анализ сайта';
      case 'marketanalyze' : return 'Маркет.анализ';
      case 'calltracking' : return 'Call Tracking';
      case 'email' : return 'E-mail';
    }
  }


  open(items, index: number): void {
    const i = [];
    items.forEach(j => {
      if (j.src) {
        i.push(j);
      }
    });
    // open lightbox
    this._lightbox.open(i, index, {fitImageInViewPort: true});
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
}
