export class User {
    uid: string;
    email: string;
    name: string;
    phone: string;
    position: string;
    roles: Roles;
    description: string;
    alias: string;
    note: string;
}

export class Roles {
    manager: boolean;
    admin: boolean;
    superAdmin: boolean;
}
