import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SenderService {
  api = 'https://eu35.chat-api.com/instance39093';
  token = '2pacuc4f68ulbg7t';

  constructor(
      private httpClient: HttpClient
  ) { }

  getStatus() {
    return this.httpClient.get<any>(`${this.api}/status?token=${this.token}`);
  }

  reloadQR() {
    return this.httpClient.get<any>(`${this.api}/qr_code?token=${this.token}`);
  }

  getMessages() {
    return this.httpClient.get<any>(`${this.api}/messages?token=${this.token}&limit=0`);
  }

  showQueue() {
    return this.httpClient.get<any>(`${this.api}/showMessagesQueue?token=${this.token}`);
  }

  clearQueue() {
    return this.httpClient.post<any>(`${this.api}/clearMessagesQueue?token=${this.token}`, {});
  }

  reboot() {
    return this.httpClient.post<any>(`${this.api}/reboot?token=${this.token}`, {});
  }

  logOut() {
    return this.httpClient.post<any>(`${this.api}/logout?token=${this.token}`, {});
  }

  sendMessage(phone, message) {
    return this.httpClient.post<any>(`${this.api}/sendMessage?token=${this.token}&phone=${phone}&body=${message}`, {});
  }

  sendFile(phone, caption) {
    return this.httpClient.post(`${this.api}/sendFile?token=${this.token}&phone=${phone}&body=https://whynet.kz/WhyNet.jpg&filename=WhyNet.jpg&caption=${caption}`, {});
  }

}

