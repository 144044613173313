import { Component, OnInit } from '@angular/core';
import {SenderService} from '../sender.service';

@Component({
  selector: 'app-sender-mailing',
  templateUrl: './sender-mailing.component.html',
  styleUrls: ['./sender-mailing.component.less']
})
export class SenderMailingComponent implements OnInit {


  list = [
      77012285761,
      77771935378,
      77052236250,
      77783303131,
      77017886763,
      77710207721,
      77071041186,
      77017578009,
      77475901820,
      77081141567,
      77075589998,
      77714132180,
      77019845035,
      77012181819,
      77773568888,
      77014185706,
      77770080666,
      77081287030,
      77011216294,
      77017369939,
      77072309905,
      77023810799,
      77073101759,
      77006099969,
      77773795858,
      77017761847,
      77776156608,
      79176587219,
      77772485502,
      77082200442,
      77088180018,
      77017413731,
      77014732085,
      77770226091,
      77479202322,
      77073003585,
      77013006187,
      77474305841,
      77473573116,
      77074651881,
      77059018147,
      77773282728,
      77016767775,
      77714102609,
      77012221712,
      77714771909,
      77770443626,
      77789089864,
      77017233598,
      77012255145,
      77789301503,
      77772799743,
      77014185706,
      77014185706,
      77777770043,
      77009741762,
      77773202565,
      77757660355,
      77762714498,
      77761802525,
      77077335546,
      77077335546,
      77027300231,
      77077579324,
      77471233391,
      77073933670,
      77089156745,
      77779708324,
      77021311233,
      77024702601,
      77017431855,
      77015262445,
      77715531135,
      77076260565,
      77015203494,
      77770063522,
      77055020444,
      77051621299,
      77011388087,
      77022222330,
      77073187978,
      77026364157,
      77019294129,
      77017448078,
      77772701891,
      77012245577,
      77772453993,
      77772144000,
      77011853791,
      77017427789,
      77058424216,
      79175028743,
      77779888839,
      77773363232,
      77014917919,
      77015353674,
      77772986530,
      77018609952,
      77077199915,
      77770063525,
      77772650617,
      77019084272,
      77015192115,
      77073817365,
      77759354605,
      77772948176
  ];

  list2 = [
      7051990813,
      77779366666,
      77054675085,
      77476669964
  ];

  wnList = [
    380501908191,
    380638257993,
    380996671033,
    380505070500,
    380636309821,
    380933669757,
  ];

    constructor(
        private senderService: SenderService
    ) {
    }

  ngOnInit() {}

  onSend() {
      this.list.forEach(number => {
        this.onSendMessage(number);
      });
  }

  onSendMessage(number) {
      const message = 'Добрый день! \nМы ждем Вас на вебинаре от компании WhyNet в 14:00. \nОтправляем ссылку - \nhttps://go.mywebinar.com/jxsv-mgdc-lbnr-zsmp  \n\nУбедительная просьба заходить через компьютер чтобы демонстрация материалов была удобной для просмотра.';
      this.senderService.sendMessage(number, message)
          .subscribe(resp => {
              if (resp.sent) {
                  console.log('Сообщение успешно отправлено', number);
              } else {
                  console.log('Не удалось отправить сообщение', number);
              }
          }, () => {
              console.log('Не удалось отправить сообщение', number);
          });
  }

}


