import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.less']
})
export class ContactListComponent implements OnInit {
  @Output() onSelectContact = new EventEmitter<any>();
  @Output() onHide = new EventEmitter<any>();
  search = '';
  contacts = [
    {name: 'Александр Порада', phone: '380505070500', company: 'whynet', website: 'whynet.kz'},
    {name: 'Алина Соломаха', phone: '380994717053', company: 'whynet', website: 'whynet.kz'},
    {name: 'Вадим Соломаха', phone: '380933669757', company: 'whynet', website: 'whynet.kz'},
    {name: 'Дмитрий Ващенко', phone: '380636309821', company: 'whynet', website: 'whynet.kz'},
    {name: 'Катерина Заруцкая', phone: '380996671033', company: 'whynet', website: 'whynet.kz'},
  ];
  constructor() { }

  ngOnInit() {
  }

  onSelect(contact) {
    this.onSelectContact.emit(contact);
  }

  hide() {
    this.onHide.emit(true);
  }




}
