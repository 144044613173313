import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class TelegramService {
  token = '1292184444:AAFZKDpeA4dWgb6almoNMGZkI0iTzSEnVqs';
  chat_id = '-486911838';
  constructor(
      private httpClient: HttpClient,
  ) { }

  sendMessage(message: string) {
    return this.httpClient.get<any>(`https://api.telegram.org/bot${this.token}/sendMessage?chat_id=${this.chat_id}&parse_mode=html&text=${message}`);
  }

}
