import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.less']
})
export class LibraryComponent implements OnInit {
  navItems = [
    {title: 'Архив встреч', icon: 'fa-file-text', link: '/meetings'},
    {title: 'База знаний', icon: 'fa-graduation-cap', link: '/library'},
    {title: 'Кейсы', icon: 'fa-briefcase', link: '/cases'},
  ];
  activeSection = 'books';
  constructor() { }

  ngOnInit() {
  }

  onChangeSection(type) {
    this.activeSection = type;
  }

}
