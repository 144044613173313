import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AngularFirestore} from "@angular/fire/firestore";
import {Meeting} from "./entities/meeting.model";

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor(
      private httpClient: HttpClient,
      private firestore: AngularFirestore,
  ) { }

  getMeeting(id) {
    return this.firestore.collection('meetings').doc(id).ref.get().then(function(doc) {
      return doc.data() as Meeting;
    });
  }

  getMeetingsList() {
    return this.firestore.collection('meetings', ref => ref.orderBy("date")).snapshotChanges();
  }

  getUserMeetingsList(userAlias) {
    return this.firestore.collection('meetings', ref => ref.where('managerAlias', '==', userAlias)).snapshotChanges();
  }

  addMeeting(meeting) {
    return this.firestore.collection('meetings').add(meeting);
  }

  updateMeeting(id, meeting) {
    return this.firestore.collection('meetings').doc(id).update(meeting);
  }

  updateMeetingStatus(id, status) {
    return this.firestore.collection('meetings').doc(id).update({status: status});
  }

  addMeetingComment(id, comments) {
    return this.firestore.collection('meetings').doc(id).update({comments: comments});
  }

  getReportsList(userAlias) {
    return this.firestore.collection('reports', ref => ref.orderBy("date").where('manager', '==', userAlias)).snapshotChanges()
  }

  addReport(report) {
    return this.firestore.collection('reports').add(report)
  }

  updateReport(id, managers) {
    return this.firestore.collection('reports').doc(id).update(managers);
  }

  getPlaningList(userAlias) {
    return this.firestore.collection('planing', ref => ref.where('manager', '==', userAlias)).snapshotChanges()
  }

  addProject(project) {
    return this.firestore.collection('planing').add(project);
  }

  editProject(id, project) {
    return this.firestore.collection('planing').doc(id).update(project);
  }

  getUsersList() {
    return this.firestore.collection('users', ref => ref.where('isAvailable', '==', true)).snapshotChanges();
  }

  getChatList() {
    return this.firestore.collection('chats').snapshotChanges();
  }

  addChat(chat) {
    return this.firestore.collection('chats').add(chat);
  }

  getEventsList(userAlias) {
    return this.firestore.collection('events', ref => ref.where('manager', '==', userAlias)).snapshotChanges();
  }

  getPageSpeed(url, type) {
    return this.httpClient.get<any>('https://www.googleapis.com/pagespeedonline/v5/runPagespeed', {
      params: {
        locale: 'ru',
        url: 'http://' + url,
        strategy: type,
        key: 'AIzaSyDMMRs7XFwamfG-PsuP-JLv3oZFDam4r0U'
      }
    });
  }

  getWeather(city: string) {
    return this.httpClient.get<any>(`https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=83075ea723fcde3e2990ce3ebc8cadcb&units=metric&lang=ru`);
  }

  // Currency rate
  getPrivatBankRate() {
    return this.httpClient.get<any>('https://api.privatbank.ua/p24api/pubinfo?exchange&json&coursid=11')
  }
  getSberbankRus() {
    return this.httpClient.get<any>('https://www.sberbank.ru/portalserver/proxy/?pipe=shortCachePipe&url=http%3A%2F%2Flocalhost%2Frates-web%2FrateService%2Frate%2Fcurrent%3FregionId%3D77%26rateCategory%3Dbase%26currencyCode%3D840')
  }
  getSberbankKz() {
    return this.httpClient.get<any>('https://www.cbr-xml-daily.ru/daily_json.js');
  }

}
