import { Component, OnInit } from '@angular/core';
import {SenderService} from "../sender.service";

@Component({
  selector: 'app-sender-messages',
  templateUrl: './sender-messages.component.html',
  styleUrls: ['./sender-messages.component.less']
})
export class SenderMessagesComponent implements OnInit {
  loading = true;
  userSideError: string;
  messages = [];
  p = 1;
  constructor(
      private senderService: SenderService
  ) { }

  ngOnInit() {
    this.getStatus();
  }

  getStatus() {
    this.loading = true;
    this.senderService.getStatus()
        .subscribe(resp => {
          this.loading = false;
          if(resp['accountStatus'] === 'authenticated'){
            this.getMessages();
          }
        }, error => {this.userSideError = error.error.error;})
  }

  getMessages() {
    this.loading = true;
    this.senderService.getMessages()
        .subscribe(resp => {
          this.loading = false;
          resp['messages'].forEach(message => {
            this.messages.push({
              number: message['messageNumber'],
              fromMe: message['fromMe'],
              phoneNumber:message['chatId'].substring(0, message['chatId'].length - 5),
              date: (new Date(message['time']*1000)).toLocaleDateString("en-US"),
              text: message['body']
            });
          })

        }, error => {this.loading = false;this.userSideError = error.error.error;})
  }
}
