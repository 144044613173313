import {Component, Input, OnInit} from '@angular/core';
import {CoreService} from "../../core.service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.less']
})
export class WeatherComponent implements OnInit {
  @Input() city: string;
  weather: any = {};
  localTime: string = '00:00';
  constructor(
      private coreService: CoreService,
      private datepipe: DatePipe,
  ) { }


  ngOnInit() {
    this.coreService.getWeather(this.city)
        .subscribe(resp=>{
          this.weather = {
            humidity: resp.main.humidity,
            pressure: resp.main.pressure,
            temp: resp.main.temp.toFixed()
          };
        });
    setInterval(()=> {this.getLocalTime(this.city); },1000);
  }

  getLocalTime(city) {
    let offset = (city === 'Kyiv') ? 3 : 6;
    let utc = new Date().getTime() + new Date().getTimezoneOffset() * 60000;
    let convertedDateTime = new Date(utc + (3600000 * offset));
    this.localTime = this.datepipe.transform(convertedDateTime, 'HH:mm');
  }
}
