import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'myfilter',
    pure: false
})
export class MyFilterPipe implements PipeTransform {
    transform(items: any[], filter: any): any {
        if (items) {}
        if (!items || !filter) {
            return items;
        }

        const website = items.filter(item => item.website ? item.website && item.website[0].toLowerCase().includes(filter.toLowerCase()) : null);
        const name = items.filter(item => item.name ? item.name.toLowerCase().includes(filter.toLowerCase()) : null);
        const company = items.filter(item => item.company ? item.company.toLowerCase().includes(filter.toLowerCase()) : null);
        const filterValue = items.filter(item => item.filterValue ? item.filterValue.toLowerCase().includes(filter.toLowerCase()) : null);

        function arrayUnique(array) {
            const a = array.concat();
            for (let i = 0; i < a .length; ++i) {
                for (let j = i + 1; j < a.length; ++j) {
                    if (a[i] === a[j]) {a.splice(j--, 1); }
                }
            }
            return a;
        }
        return arrayUnique(arrayUnique(website.concat(name).concat(company).concat(filterValue)));
    }
}
