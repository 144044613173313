import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SenderService } from './sender.service';
import { SenderComponent } from './sender.component';
import { SenderDashboardComponent } from './sender-dashboard/sender-dashboard.component';
import { SenderDocumentationComponent } from './sender-documentation/sender-documentation.component';
import { CoreModule } from '../core/core.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { SenderMessagesComponent } from './sender-messages/sender-messages.component';
import { NewMessageComponent } from './new-message/new-message.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SenderMailingComponent } from './sender-mailing/sender-mailing.component';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        RouterModule,
        NgxPaginationModule,
        ReactiveFormsModule,
        PickerModule,
    ],
  declarations: [
      SenderComponent,
      SenderDashboardComponent,
      SenderDocumentationComponent,
      SenderMessagesComponent,
      NewMessageComponent,
      SenderMailingComponent,
  ],
  providers: [
      SenderService
  ]
})
export class SenderModule { }
