import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './app.routes';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { environment } from '../environments/environment';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardResolver } from './dashboard/dashboard.resolver';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuard } from './core/auth.guard';
import { AdminGuard } from './core/admin.guard';
import { AuthService } from './core/auth.service';
import { UserService } from './core/user.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AppComponent } from './app.component';
import { DashboardService } from './dashboard/dashboard.service';
import { AdminModule } from './admin/admin.module';
import { CoreModule } from './core/core.module';
import { ChartsModule } from 'ng2-charts';
import { NgxPaginationModule } from 'ngx-pagination';
import { AlertModule } from 'ngx-bootstrap/alert';
import { NewMeetingComponent } from './meetings/new-meeting/new-meeting.component';
import { LibraryComponent } from './library/library.component';
import { MeetingListComponent } from './meetings/meeting-list/meeting-list.component';
import { MeetingDetailsComponent } from './meetings/meeting-details/meeting-details.component';
import { MeetingsComponent } from './meetings/meetings.component';
import { MeetingResolver } from './meetings/meeting-details/meeting-details.resolver';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DailyReportComponent } from './daily-report/daily-report.component';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TypeaheadModule} from 'ngx-bootstrap';
import { PlaningComponent } from './planing/planing.component';
import { ChatComponent } from './chat/chat.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SenderModule} from './sender/sender.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import {SafePipe} from './core/pipes/safe.pipe';
import {LightboxModule} from 'ngx-lightbox';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        DashboardComponent,
        NotFoundComponent,
        NewMeetingComponent,
        MeetingsComponent,
        MeetingListComponent,
        MeetingDetailsComponent,
        LibraryComponent,
        DailyReportComponent,
        PlaningComponent,
        ChatComponent,
        SafePipe
    ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes, {useHash: false}),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule, // imports firebase/firestore, only needed for database features
        AngularFireAuthModule, // imports firebase/auth, only needed for auth features
        NgbModule.forRoot(),
        AngularFontAwesomeModule,
        CoreModule,
        AdminModule,
        SenderModule,
        ChartsModule,
        NgxPaginationModule,
        AlertModule.forRoot(),
        FormsModule,
        Ng2SearchPipeModule,
        BrowserAnimationsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        TypeaheadModule.forRoot(),
        TooltipModule.forRoot(),
        BsDropdownModule.forRoot(),
        PickerModule,
        LightboxModule
    ],
    providers: [
        AuthService,
        UserService,
        DashboardResolver,
        AuthGuard,
        AdminGuard,
        AngularFireDatabase,
        DashboardService,
        MeetingResolver,
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
