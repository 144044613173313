export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCyF2stRCCGkZJZb0gHc_Uf73emDqeByuo',
    authDomain: 'whynet-32c9d.firebaseapp.com',
    databaseURL: 'https://whynet-32c9d.firebaseio.com',
    projectId: 'whynet-32c9d',
    storageBucket: 'whynet-32c9d.appspot.com',
    messagingSenderId: '1054405889970'
  }
};
