import {Component, Input, OnInit} from '@angular/core';
import { Meeting } from '../../core/entities/meeting.model';
import { ActivatedRoute, Router } from '@angular/router';
import {AuthService} from "../../core/auth.service";
import {CoreService} from "../../core/core.service";
import * as firebase from "firebase";

@Component({
  selector: 'app-meeting-list',
  templateUrl: './meeting-list.component.html',
  styleUrls: ['./meeting-list.component.less']
})
export class MeetingListComponent implements OnInit {
    @Input() userAlias: string;
    constructor(
        private coreService: CoreService,
        private route: ActivatedRoute,
        private auth: AuthService,
        private router: Router
    ) {}
    p = 1;
    meetings: Meeting[];
    user = {};
    term: any;
    isMeetingStarted = false;

    ngOnInit() {
        this.isMeetingStarted =  !!sessionStorage.getItem('meeting');
        this.auth.user$.subscribe(user => {
            this.user = user;
            this.getList();
        });
    }

    getList() {
        if(this.userAlias){
            this.coreService.getUserMeetingsList(this.userAlias).subscribe(data => {
                this.meetings = data.map(e => {
                    return { id: e.payload.doc['id'], ...e.payload.doc.data() } as Meeting;
                });
            });
        } else{
            this.coreService.getMeetingsList().subscribe(data => {
                this.meetings = data.reverse().map(e => {
                    return { id: e.payload.doc['id'], ...e.payload.doc.data() } as Meeting;
                });
            });
        }
    }

    openDetailsPage(id) {
        this.router.navigate(['/meetings/' + id], { relativeTo: this.route });
    }

    onGetDate(timestamp) {
        return new Date(timestamp.seconds * 1000).toLocaleDateString()
    }

}
