import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {combineLatest, Subscription} from "rxjs";
import {CoreService} from "../../core.service";

@Component({
  selector: 'app-currency-rate',
  templateUrl: './currency-rate.component.html',
  styleUrls: ['./currency-rate.component.less']
})
export class CurrencyRateComponent implements OnInit {
  today = new Date();
  currencies: {
    uah: { coefficient: number, value: number },
    rub: { coefficient: number, value: number },
    kzt: { coefficient: number, value: number },
    usd: { coefficient: number, value: number }
  } = {
    uah: {coefficient: 0, value: 0.00},
    rub: {coefficient: 0, value: 0.00},
    kzt: {coefficient: 0, value: 0.00},
    usd: {coefficient: 1, value: 1.00}
  };

  form: FormGroup;
  subs: Subscription;

  constructor(
      private coreService: CoreService
  ) {
  }

  ngOnInit() {
    this.getCurrencyRates();
  }

  getCurrencyRates() {
    combineLatest(
        this.coreService.getPrivatBankRate(),
        this.coreService.getSberbankKz(),
        this.coreService.getSberbankRus(),
    ).subscribe(([ua, kz, ru]) => {
      ua.forEach(c => {
        if (c.ccy == "USD") {
          let perDollar = c.sale;
          this.onSetCurrencyCoefficient('uah', perDollar);
        }
      });
      this.onSetCurrencyCoefficient('rub', ru['base']['840']['0']['sellValue']);
      this.onSetCurrencyCoefficient('kzt', (Number(kz.Valute.USD.Value) / (Number(kz.Valute.KZT.Value) / 100)));
      this.onInitForm();
    })
  }

  private onInitForm() {
    this.form = new FormGroup({
      uah: new FormControl(this.currencies.uah.value, []),
      rub: new FormControl(this.currencies.rub.value, []),
      kzt: new FormControl(this.currencies.kzt.value, []),
      usd: new FormControl(this.currencies.usd.value, []),
    });
    this.onWatch();
  }

  onWatch() {
    this.subs = this.form.valueChanges
        .subscribe(form => {
          Object.keys(this.form.controls).forEach(ctrl => {
            if (this.form.controls[ctrl].dirty) {
              this.onRecalculate(ctrl);
            }
          });
        })
  }

  onSetCurrencyCoefficient(currency: string, perDollar: number) {
    this.currencies[currency]['value'] = Number(Number(perDollar)).toFixed(2);
    this.currencies[currency]['coefficient'] = Number(perDollar);
  }

  onRecalculate(ctrlName: string) {
    Object.keys(this.currencies).forEach(abbr => {
      if (this.currencies[abbr] !== ctrlName) {
        this.currencies[abbr]['value'] = Number((this.form.controls[ctrlName].value / this.currencies[ctrlName]['coefficient']) * this.currencies[abbr]['coefficient']).toFixed(2);
      }
    });
    this.subs.unsubscribe();
    this.onPatchValue(ctrlName);
  }

  onPatchValue(attr: string) {
    Object.keys(this.form.controls).forEach(ctrl => {
      if (ctrl !== attr) {
        this.form.controls[ctrl].setValue(this.currencies[ctrl]['value']);
        this.form.markAsUntouched();
        this.form.markAsPristine();
      }
    });
    this.onWatch();
  }
}
