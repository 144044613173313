import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from '../core/entities/user.model';
@Injectable()
export class AdminService {

  constructor(
      private firestore: AngularFirestore
  ) { }

  getUsersList() {
    return this.firestore.collection('users').snapshotChanges();
  }

  getUser(id) {
    return this.firestore.collection('users').doc(id).ref.get().then(function(doc) {
      return doc.data() as User;
    });
  }

  addUser(user: User) {
    return this.firestore.collection('users').add(user);
  }


  deleteMeeting(meetingKey) {
      return this.firestore.collection('meetings').doc(meetingKey).delete();
  }

  getCredentialsList() {
    return this.firestore.collection('credentials').snapshotChanges();
  }

  addCredentials(data) {
    return this.firestore.collection('credentials').add(data);
  }

  deleteCredentials(data) {
    return this.firestore.collection('credentials').doc(data).delete();
  }

}


