import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Meeting } from "../../core/entities/meeting.model";
import { CoreService } from "../../core/core.service";

@Injectable()
export class MeetingResolver implements Resolve<{data: Meeting}> {

    constructor(public coreService: CoreService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot): Promise<{data: Meeting}> {

        return new Promise((resolve, reject) => {
            this.coreService.getMeeting(route.params['id'])
                .then(() => {
                    return resolve(route.params['id']);
                }, err => {});
        });
    }
}
