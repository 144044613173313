import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Meeting} from '../../core/entities/meeting.model';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../core/core.service';
import {AuthService} from '../../core/auth.service';

@Component({
  selector: 'app-meeting-details',
  templateUrl: './meeting-details.component.html',
  styleUrls: ['./meeting-details.component.less']
})
export class MeetingDetailsComponent implements OnInit {
    @ViewChild('commentInput') commentInput: ElementRef;
    @ViewChild('commentsWrapper') commentsWrapper: ElementRef;
    meeting: Meeting;
    meetingId = this.route.snapshot.data.meetingData;
    p = 1;
    user = {};
    isMeetingStarted = false;
    constructor(
        private coreService: CoreService,
        private route: ActivatedRoute,
        private auth: AuthService,
        private router: Router
    ) { }

    ngOnInit() {
        this.isMeetingStarted =  !!sessionStorage.getItem('meeting');
        this.auth.user$.subscribe(user => {this.user = user; });
        this.coreService.getMeeting(this.meetingId).then(meeting => {
            this.meeting = meeting;
        });
    }

    onRepeatMeeting() {
        sessionStorage.setItem('meetingId', this.meetingId);
        this.router.navigate(['/meetings/new']);
    }

    isRecommendationsExist() {
        return (this.meeting.recommendations.analytics ||
            this.meeting.recommendations.websiteAnalyze ||
            this.meeting.recommendations.marketAnalyze ||
            this.meeting.recommendations.youTube ||
            this.meeting.recommendations.callTracking ||
            this.meeting.recommendations.context ||
            this.meeting.recommendations.seo ||
            this.meeting.recommendations.smm ||
            this.meeting.recommendations.email ||
            this.meeting.recommendations.web);
    }

    isInternetActivitiesExist() {
        return (this.meeting['context'] ||
        this.meeting['seo'] ||
        this.meeting['smm'] ||
        this.meeting['youTube'] ||
        this.meeting['callTracking']);
    }

    isGeneralInfoExist() {
        return (this.meeting['type'] ||
        this.meeting['age'] ||
        this.meeting['staff'] ||
        this.meeting['audience'] ||
        this.meeting['geography'] ||
        this.meeting['source'] ||
        this.meeting['cost'] ||
        this.meeting['priority'] ||
        this.meeting['stat']);
    }

    getUpdatedLink(link) {
        return link.includes('http') ?  link : 'https://' + link;
    }

    onChangeStatus(status) {
        this.coreService.updateMeetingStatus(this.meetingId, status).then(() => {this.meeting['status'] = status; });
    }

    keyDownFunction(event) {
        if (event.keyCode === 13) {
            const comment = this.commentInput.nativeElement.value;
            if (comment.length > 5) {
                this.meeting['comments'].push({text: this.commentInput.nativeElement.value, author: this.user['name']});
                this.coreService.addMeetingComment(this.meetingId, this.meeting['comments']).then(() => {
                    this.commentInput.nativeElement.value = '';
                });
            }
            this.scrollToBottom();
        }
    }

    scrollToBottom(): void {
        try {this.commentsWrapper.nativeElement.scrollTop = this.commentsWrapper.nativeElement.scrollHeight; } catch (err) { }
    }

    getTagsFromString(str): string[] {
      const localStr = str + '';
      return localStr.split(',');
    }
}
