import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.less']
})
export class NoResultsComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  constructor() { }

  ngOnInit() {
    this.title =  this.title ? this.title : 'Нет данных для отображения';
    this.text =  this.text ? this.text : 'Информация не найдена или отсутствует';
  }

}
