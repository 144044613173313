import { Component, OnInit } from '@angular/core';
import {AuthService} from '../core/auth.service';

@Component({
  selector: 'dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.less']
})
export class DashboardComponent implements OnInit {

  navItems = [
    {title: 'Статистика', icon: 'fa-line-chart', link: '/', isActive: true},
    {title: 'Архив встреч', icon: 'fa-file-text', link: '/meetings'},
    {title: 'Планирование', icon: 'fa-pie-chart', link: '/planing'},
    {title: 'Ежедневный отчет', icon: 'fa-file', link: '/report'},
    // {title: 'Календарь', icon: 'fa-calendar', link: '/calendar'},
    // {title: 'Кейсы', icon: 'fa-briefcase', link: '/cases'},
    // {title: 'База знаний', icon: 'fa-graduation-cap', link: '/library'},
  ];

  user = {};

  constructor(
      private auth: AuthService
  ) {}

  ngOnInit() {
    this.auth.user$.subscribe(user => {
      this.user = user;
    });
  }

}
