import { Component, OnInit } from '@angular/core';
import {CoreService} from "../core/core.service";
import {AuthService} from "../core/auth.service";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.less']
})
export class ChatComponent implements OnInit {
  navItems = [
    {title: 'Статистика', icon: 'fa-line-chart', link: '/'},
    {title: 'Архив встреч', icon: 'fa-file-text', link: '/meetings'},
    {title: 'Планирование', icon: 'fa-pie-chart', link: '/planing'},
    {title: 'Чат', icon: 'fa-comments', link: '/chat', isActive: true},
    {title: 'Ежедневный отчет', icon: 'fa-file', link: '/report'}
  ];
  user = {};
  contact: string;
  contacts = [];
  isCollapsed = true;
  chats = [];
  constructor(
      private coreService: CoreService,
      private auth: AuthService,
  ) { }


  ngOnInit() {
    this.onGetCurrentUser();
    this.onGetUserList();
    this.onGetChatList();
  };

  onGetCurrentUser() {
    this.auth.user$.subscribe(user => {
      this.user = user;
    });
  }

  onGetUserList() {
    this.coreService.getUsersList().subscribe(data => {
      this.contacts = data.map(e => {
        // @ts-ignore
        return {id: e.payload.doc['id'], ...e.payload.doc.data()} as any;
      });
    });
  }

  onGetChatList() {
    this.coreService.getChatList().subscribe(data => {
      this.chats = data.map(e => {
        // @ts-ignore
        return {id: e.payload.doc['id'], ...e.payload.doc.data()} as any;
      });
    });
  }

  onGetUserName(alias) {
    this.contacts.forEach(contact => {
      if(contact.alias === alias){return contact.name;}
    })
  }

  onOpenChat() {
    this.chats.forEach(chat => chat.isActive === false);
    this.chats.forEach(chat => {
      if(chat.members && chat.members.length === 2){
        if((chat.members[0] === this.user['alias']) || chat.members[1] === this.user['alias']) {
          if((chat.members[0] === 'asolomakha') || chat.members[1] === 'asolomakha') {
            chat.isActive = true;
          }
        }
      }
    })
  }

}

