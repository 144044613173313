import {Component, OnInit} from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit{
  isCollapsed = true;
  user = {};
  constructor(
    public authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.authService.user$.subscribe(user => {
      this.user = user;
    });
  }

  logout() {
    this.authService.doLogout()
      .then(() => {
          this.router.navigate(['/login']);
      }, (error) => {
        console.log('Logout error', error);
      });
  }

}
