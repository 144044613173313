import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {CoreService} from '../core/core.service';
import {DatePipe} from '@angular/common';
import {AuthService} from '../core/auth.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TelegramService} from '../shared/services/telegram.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ruLocale } from 'ngx-bootstrap/locale';
import {BsLocaleService} from 'ngx-bootstrap';
import * as firebase from 'firebase/app';

defineLocale('ru', ruLocale);
@Component({
  selector: 'app-daily-report',
  templateUrl: './daily-report.component.html',
  styleUrls: ['./daily-report.component.less']
})
export class DailyReportComponent implements OnInit, OnDestroy {
  navItems = [
    {title: 'Статистика', icon: 'fa-line-chart', link: '/'},
    {title: 'Архив встреч', icon: 'fa-file-text', link: '/meetings'},
    {title: 'Планирование', icon: 'fa-pie-chart', link: '/planing'},
    {title: 'Ежедневный отчет', icon: 'fa-file', link: '/report', isActive: true},
  ];
  currentMonth = this.datepipe.transform(new Date(), 'MM');
  months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

  constructor(
      private coreService: CoreService,
      private auth: AuthService,
      private datepipe: DatePipe,
      private localeService: BsLocaleService,
      private telegramService: TelegramService
  ) { }

  p = 1;
  form: FormGroup;
  user = {};
  reports = [];
  isEditMode = false;
  temp = {date: null, contacts: '', meetings: '', coldMeetings: '', clients: '', money: '', comment: '', isEditMode: true};
  private destroyed$ = new Subject();
  isLoading = true;
  locale = 'ru';

  ngOnInit() {

    this.localeService.use(this.locale);
    this.auth.user$.subscribe(user => {
      this.user = user;
      this.getReportList();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  onChangeMonth(month) {
    this.currentMonth = ('0' + month).slice(-2);
    this.getReportList();
  }

  getReportList() {
    this.coreService.getReportsList(this.user['alias'])
        .pipe(takeUntil(this.destroyed$))
        .subscribe(data => {
          if(!data.length){
            this.isLoading = false;
          }
          this.reports = data.map(e => {
            this.isLoading = false;
            return {id: e.payload.doc['id'], ...e.payload.doc.data()};
          });
          this.reports.forEach(item => {
            item.filterValue = this.datepipe.transform(item.date.toDate(), 'MM');
            item.date = this.datepipe.transform(item.date.toDate(), 'dd.MM.yyyy');
            item.isEditMode = false;
          });
        });

  }

  onAddReport() {
    this.temp.isEditMode = true;
    this.temp.date = this.datepipe.transform(new Date(), 'dd.MM.yyyy');
    this.temp['filterValue'] = this.currentMonth;
    this.reports.unshift(this.temp);
    this.isEditMode = true;
  }

  onCancel() {
    this.isEditMode = false;
    this.reports.shift();
    this.temp = {date: null, contacts: '', meetings: '', coldMeetings: '', clients: '', money: '', comment: '', isEditMode: true};
  }

  onChangeDate(event) {
    this.temp['date'] = this.datepipe.transform(event, 'dd.MM.yyyy');
  }

  onGetTimeStemp(date) {
    date = date.split( '.');
    const newDate = date[1] + '/' + date[0] + '/' + date[2];
    return firebase.firestore.Timestamp.fromDate(new Date(newDate)).toDate();
  }

  onSave(item) {
    if (this.temp['date']) {this.temp['date'] = this.onGetTimeStemp(this.temp['date']) }
    if (!item.id) {
      item.isEditMode = false;
      this.isEditMode = false;
      this.temp['manager'] = this.user['alias'];
      this.coreService.addReport(this.temp);
    } else {
      item.isEditMode = false;
      this.isEditMode = false;
      if (this.temp.date || this.temp.contacts || this.temp.meetings || this.temp.coldMeetings || this.temp.clients || this.temp.money || this.temp.comment) {
        !this.temp.date ? this.temp.date = this.onGetTimeStemp(item.date) : null;
        !this.temp.contacts ? this.temp.contacts = item.contacts : null;
        !this.temp.meetings ? this.temp.meetings = item.meetings : null;
        !this.temp.coldMeetings ? this.temp.coldMeetings = item.coldMeetings : null;
        !this.temp.clients ? this.temp.clients = item.clients : null;
        !this.temp.money ? this.temp.money = item.money : null;
        !this.temp.comment ? this.temp.comment = item.comment : null;
        this.coreService.updateReport(item.id, this.temp);
        this.isEditMode = false;
      }

      this.temp = {date: null, contacts: '', meetings: '', coldMeetings: '', clients: '', money: '', comment: '', isEditMode: false};
    }
  }

  sendToTelegram(message) {
    this.telegramService.sendMessage(message)
      .subscribe(
          resp => {console.log(resp); },
          err => {console.log(err); }
      );
  }
}
